import React, { useState } from "react";
import {
    Form,
    Input,
    Select,
    Upload,
    Modal,
    Button,
    Space,
    Alert
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { TATTOO_URL } from "../../../Helper";

const Option = Select.Option;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const removeImage = async (data) => {
    const res = await axios.post(
        TATTOO_URL + "api/media_delete",
        {
            media_image_id:data.response
        }
    ).then((res) => {
        console.warn({"imag deelted":res})
    }).catch();
}

const StepFive = (props) => {
    console.log("Props", props)
    const [form] = Form.useForm();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [imageUrl, setImageUrl] = useState();

    const handleCancel = () => setPreviewOpen(false);
    
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const uploadImage = async (options) => {
        const { onSuccess, onError, file } = options;

        const fmData = new FormData();
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
        };
        fmData.append("file", file);
        try {
            const res = await axios.post(
                TATTOO_URL + "api/media_uploads",
                fmData,
                config
            );
            onSuccess(res?.data?.data?.id);
            setImageUrl(res);
            console.log("server res: ", res, imageUrl);
        } catch (err) {
            console.log("Error: ", err);
            const error = new Error("Some error");
            onError({ err });
            console.warn(error);
        }
    };

    console.log(props.artistData)

    return (
        <div className="stepWrapper">
            <div className="stepwrapInner">
                <div className="stepTitle">
                    <h4>Create a new album</h4>
                    <p>Create an album to showcase your work.</p>
                </div>
                <Form
                    layout="vertical"
                    form={form}
                    className="businessForm"
                >
                    <Form.List 
                        name="albums"
                    >
                        {(fields, { add, remove }, { errors }) => (
                            props.artistData?.plan_id === 1 ? (
                                fields.length >= 5 ? add = undefined : ''
                            ) : props.artistData?.plan_id === 5 || props.artistData?.plan_id === 7 ? (
                                fields.length >= 50 ? add = undefined : ''
                            ) : (''),
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} className="d-flex w-100 repeater" size={30}>
                                        <div className="w-100">
                                            <Form.Item
                                                {...restField}
                                                name={[name, "album_name"]}
                                                label="Album Name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter album name here!",
                                                    },
                                                ]}
                                                className="w-100"
                                                key={key}
                                            >
                                                <Input
                                                    className="formControl"
                                                    placeholder="Enter album name here"
                                                    onChange={(e) =>props.handleRepeater(e, key, "name")}
                                                    defaultValue={props.profileInput.album_name}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "album_tag_ids"]}
                                                label="Album Tags"
                                                className="w-100"
                                                key={key}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select album tag here!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="What type of tattoos do you provide?"
                                                    className="formControl h-auto"
                                                    mode="multiple"
                                                    allowClear
                                                    onChange={(e) => props.handleRepeater(e, key, "tags")}
                                                  //  defaultValue={props.albums[key].tags}
                                                >
                                                    {props.tags.map((tag) => {
                                                        return (
                                                            <Option value={tag} id={tag}>
                                                                {tag}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="w-100 d-flex align-items-center">
                                            <Form.Item
                                                {...restField}
                                                name={[name, "albumImages"]}
                                                label="Album Images"
                                                className="flex-wrap w-100"
                                                key={key}
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please fill album image here!",
                                                //     },
                                                // ]}
                                            >
                                                <Upload
                                                    listType="picture-card"
                                                    //fileList={fileList}
                                                    onPreview={handlePreview}
                                                    onChange={props.handleChangeImage(key)}
                                                    onRemove = {removeImage} 
                                                    maxCount={
                                                        props.artistData?.plan_id === '1' ? 10  : 
                                                        props.artistData?.plan_id === '5' ? 50 :
                                                        props.artistData?.plan_id === '7' ? 50 :
                                                        100
                                                    }
                                                    customRequest={uploadImage}
                                                >
                                                    {props.fileList.length >= 10 ? null : uploadButton}
                                                </Upload>
                                                <Modal
                                                    open={previewOpen}
                                                    title={previewTitle}
                                                    footer={null}
                                                    onCancel={handleCancel}
                                                >
                                                    <img
                                                        alt="example"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        src={previewImage}
                                                    />
                                                </Modal>
                                            </Form.Item>
                                            <Button
                                                className="submitBtn w-auto h-auto"
                                                onClick={() => remove(name)}
                                            >
                                                <MinusCircleOutlined />
                                            </Button>
                                        </div>
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button className="submitBtn w-auto" onClick={() => add()} disabled={!add} >
                                        Add Album
                                    </Button>
                                </Form.Item>
                                {props.artistData?.plan_id === 1 && fields.length >= 5 ? (
                                    <Alert
                                        message="If you want to exceed images within album please upgrade your plan."
                                        type="info"
                                        showIcon
                                        closable
                                        className='mb-5'
                                    />
                                ) : ('') }
                                {/* <Form.ErrorList errors={errors} /> */}
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
        </div>
    );
};

export default StepFive;
