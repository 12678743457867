import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BGImg } from '../../constant/images';
import * as resetPassword from "../../services/resetPasswordService";
import { useDispatch } from "react-redux";
import { CheckCircleOutlined } from "@ant-design/icons";

const ResetPassword = () => {
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [loadings, setLoadings] = useState([]);
  const [loginInput, setLoginInput] = useState({
    password: '',
    password_confirmation: '',
  })

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const email = localStorage.getItem('user_email');

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const handleInput = (e) => {
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
  }

  const onFinish = (values) => {
    console.log('Success:', values);
    enterLoading(0);
    const data = {
      password: loginInput.password,
      password_confirmation: loginInput.password_confirmation,
      token: token,
      email: email,
    }
    dispatch(resetPassword.resetPassword(data))
      .then((res) => {
        notification.open({
          message: "Password successfully reset!",
          icon: (
            <CheckCircleOutlined
              style={{
                color: '#d62b47',
              }}
            />
          ),
        });
        navigate('/login')
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const leftSectionStyle = {
    backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    height: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <div className='authWrapper'>
      <div className='authWrapInner'>
        <div className='container-fluid'>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify='center'>
            <Col xs={24} md={24} xl={16}>
              <Card bordered={false} className="authCard">
                <Row>
                  <Col xs={24} md={12}>
                    <div className='leftSection' style={leftSectionStyle}>
                      <div className='contentSection'>
                        <h3 className='secTitle'>Welcome</h3>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className='rightSection secPadding'>
                      <div className='formWrap'>
                        <div className='formTitle'>
                          <h3 className='frmTitle'>Create a New Password</h3>
                          <p className='subTitle'>Your password should have at least 8 characters with at least one symbol and one number.</p>
                        </div>
                        <Form
                          name='passwordForm'
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: 'Password should have at least 8 characters with at least one symbol and one number.',
                                pattern: new RegExp(/^(?=.*[a-z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/)
                              },
                            ]}
                            hasFeedback
                          >
                            <Input.Password 
                              name="password" 
                              placeholder='Password' 
                              className="formControl"
                              onChange={handleInput} 
                              value={loginInput.password}
                              minLength={8}
                            />
                          </Form.Item>
                          <Form.Item
                            name="password_confirmation"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: 'Please confirm your password!',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                              }),
                            ]}
                          >
                            <Input.Password 
                              name="password_confirmation" 
                              placeholder='Confirm Password' 
                              className="formControl"
                              onChange={handleInput} 
                              value={loginInput.password_confirmation}
                            />
                          </Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadings[0]}
                            className="submitBtn"
                          >Submit </Button>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;