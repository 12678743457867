import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Card } from 'antd';
// import { PageBanner } from '../../components/Banner/pageBanner';
import * as privacyService from '../../services/privacyService';
import { useDispatch } from "react-redux";
import * as getTermConditionTemplate from "../../services/getTermConditionService";


const Terms = () => {
    var dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [terms, setTerms] = useState([]);
    const [termData, setTermData] = useState([]);

    const bannerStyle = {
        backgroundImage: `url(${termData?.template_photo})`,
        backgroundSize: 'cover',
        height: '450px',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
    const getTerms = (setting_name) => {
        dispatch(privacyService.getTerms(setting_name))
            .then((res) => {
                setLoading(true);
                console.info(res);
                setTerms(res?.data?.data);
                setLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    }
    const getTermandConditionData = () => {
        dispatch(getTermConditionTemplate.getTermAndConditionService())
        .then((res) => {
            setLoading(true);
            console.info(res);
            setTermData(res?.data);
            setLoading(false);
        })
        .catch((err) => {
            console.info("Get Error Edit State...");
        });
    }


    useEffect(() => {
        getTerms();
        getTermandConditionData();
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='faqWrapper'>
            <div className='faqInnerWrap'>
                {/* <PageBanner
                    bannerText="Terms and"
                    colorText="Conditions"
                    bannerSubText="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet."
                /> */}
                 <div className='bannerWrapper'>
            <div className='innerWrap'>
                <div className='bannerSec' style={bannerStyle}>
                    <div className='bannerContent'>
                        <h3 className='secTitle'>{termData?.template_name}
                        {/* <span className='colorText'>{colorText}</span> */}
                        </h3>
                        <p className='subText'>{termData?.sub_heading}</p>
                    </div>
                </div>
            </div>
        </div>
                <div className='container'>
                    {loading ?
                        (
                            <div className='spinDiv'>
                                <Spin size='large' />
                            </div>
                        ) :
                        (
                            <div className='faqWrap'>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                                    <Col xs={24} md={24} xl={24}>
                                        <Card className='cmsCard' bordered={false} title="Terms and Conditions">
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: terms[0]?.setting_value,
                                                }}
                                            ></p>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
}

export default Terms;