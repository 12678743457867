import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Input, Button, Form, Avatar, Spin } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import * as messageService from '../../services/sendMessage';
import { useLocation } from 'react-router-dom';

const ViewMessgae = () => {
    const { state } = useLocation();
    let old_userId = state?.userId;
    const userId = parseInt(old_userId);
    var dispatch = useDispatch();
    const userDetails = useSelector((state) => state?.User?.userDetails);
    const queryParams = new URLSearchParams(window.location.search);
    const artist_id = queryParams.get("to_id");
    const customer_id = queryParams.get("from_id");
    const msg_name = queryParams.get("name");
    const [msgThread, setMsgThread] = useState([]);
    const [chatInput, setChatInput] = useState({
        message: '',
    });
    const [loading, setLoading] = useState(true)
    const { TextArea } = Input;
    // const old_Login_Id = localStorage.getItem('Login_Id');
    // const Login_Id = parseInt(old_Login_Id);

    const handleInput = (e) => {
        if (e.keyCode == 13 && e.shiftKey) {  
            setChatInput({ ...chatInput, [e.target.name]: e.target.value });
        } else {
            setChatInput({ ...chatInput, [e.target.name]: e.target.value });
        }
    }

    const fatchThread = (from_id, to_id) => {
        dispatch(messageService.getMessageThreads(from_id, to_id))
            .then((res) => {
                setLoading(true);
                setMsgThread(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.info({ err });
            })
    }
   
    const handleKeyPress = (e) => {
        if (e.keyCode == 13 && e.shiftKey) {
            setChatInput({ ...chatInput, [e.target.name]: e.target.value });
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fatchThread(customer_id, artist_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // console.info("userId",userId);
    // console.info("userDetails",userDetails);
    const chatSubmit = () => {
        const data = {
            message: chatInput?.message,
            to_id: userDetails.role[0] === "Artist" ? customer_id : artist_id,
            from_id: userDetails?.user_id ? userDetails?.user_id : userId,
            role: userDetails.role[0] ?? userDetails?.role
        }
        console.info({data})
        dispatch(messageService.sendMessage(data))
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log({ err });
            })
    }

    return (
        <div className='inboxWrapper'>
            <div className='inboxInnerWrap'>
                <div className='container-fluid'>
                    {loading ? (
                        <div className='spinDiv'>
                            <Spin size='large' />
                        </div>
                    ) : (
                        <div className='contentWrap msgWrap'>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify="center">
                                <Col xs={24} md={14}>
                                    <div className='tableWrap'>
                                        <Card
                                            title={
                                                <>
                                                    <Avatar className='chatAvt'>{msg_name.split(' ').map(word => word[0]).join('')}</Avatar>
                                                    {msg_name}
                                                </>
                                            }
                                            bordered={false}
                                            className="tableCard inboxCard"
                                        >
                                              
                                            <div className='cardInner'>
                                                <div className='msgThread'>
                                                    {msgThread.map((textMsg, i) => {
                                                        return (
                                                            <>

                                                                {userDetails?.role[0] === 'Artist' ? (
                                                                    <>
                                                                        {textMsg.from_id == userId ?
                                                                            <div className='msgText sendar' key={i}>
                                                                                 <span className='text'
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: textMsg.message,
                                                                                    }}
                                                                                ></span>
                                                                            </div> :
                                                                            <div className='msgText' key={i}>
                                                                                 <span className='text'
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: textMsg.message,
                                                                                    }}
                                                                                ></span>
                                                                         
                                                                            </div>
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {textMsg.from_id == customer_id ?
                                                                            <div className='msgText sendar' key={i}>
                                                                                <span className='text'
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: textMsg.message,
                                                                                    }}
                                                                                ></span>
                                                                            </div> :
                                                                            <div className='msgText' key={i}>
                                                                                 <span className='text'
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: textMsg.message,
                                                                                    }}
                                                                                ></span>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                ) }
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                                <div className='sendField'>
                                                    <Form.Item name='message' rules={[
                                                    
                                                   
                                                    {
                                                    maxLength: 1024,
                                                    message: "Enter valid Zip Code.",
                                                    },
                                                    ]}>
                                                        <TextArea
                                                            rows={1}
                                                            res
                                                            name="message"
                                                            placeholder='Enter your message here...'
                                                            className='formControl'
                                                            onChange={handleInput}
                                                            onKeyUp={handleKeyPress}
                                                            id="messageBox"
                                                            style={{
                                                                resize: 'none',
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Button
                                                        type='primary'
                                                        className='submitBtn w-auto'
                                                        onClick={() => chatSubmit()}
                                                    >Send</Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ViewMessgae;