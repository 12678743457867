import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Row,
  Col,
  Segmented,
  Image,
  Card,
  Rate,
  Avatar,
  Tooltip,
  Divider,
  Tag,
  Space,
  Modal,
  Form,
  Input,
  Spin,
  notification,
  Button,
  Radio,
  Collapse,
  Checkbox,
  Result,
  Empty,
  Badge,
  Pagination,
  Carousel,
} from "antd";
import { Link } from "react-router-dom";
import {
  AiOutlineAppstore,
  AiOutlineBars,
  AiOutlineDown,
} from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { FaFilter, FaRegBookmark,FaBookmark, FaRegEnvelope, FaPlus } from "react-icons/fa";
import { CardCover, Logo, AvatarImg } from "../../constant/images";
import { useDispatch, useSelector } from "react-redux";
import * as profileService from "../../services/createProfile";
import * as messageService from "../../services/sendMessage";
import { IconButton, SecondaryButton } from "../../components/Buttons/buttons";
import { CheckCircleOutlined, SmileOutlined } from "@ant-design/icons";
import * as categoryService from "../../services/categoryService";
import * as certificateService from "../../services/certificateService";
import * as filterApi from "../../services/searchFilter";
import * as saveArtist from "../../services/saveArtistDataService";
import * as getHomePageService from "../../services/getHomePageService";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import localStorage from "redux-persist/es/storage";

const { Meta } = Card;
const { Panel } = Collapse;

const Index = () => {
  var dispatch = useDispatch();
  const [isNewModal, setNewModal] = useState(false);
  const [artistList, setArtistList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const [user, setUser] = useState([]);
  console.log(user,"user")
  const [userId, setUserid] = useState();
  const [homeTemplateData, setHomeTemplateData] = useState("");
  const [userArtuistId, setUserArtuistId] = useState();
  const [loading, setLoading] = useState(true);
  const [artistContent, setArtistContent] = useState("");
  const userDetails = useSelector((state) => state?.User?.userDetails);
  const [loadings, setLoadings] = useState([]);
  const [form] = Form.useForm();
  const [grid, setGrid] = useState("List");
  console.log(grid,"grid")
  const [search, setSearch] = useState("");
  const [folderName, setFolderName] = useState("");
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [filter, setFilter] = useState({});
  const [showInputButton, setShowInputButton] = useState(false);
  const [showMore, setShowMore] = useState({});
  const formRef = useRef(null);
  const [checkedValues, setCheckedValues] = useState([]);
  const [customerID, setCustomerId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const[ averageRatings,setAverageRatings] = useState();
  const [messageInput, setMessageInput] = useState({
    name: "",
    email: "",
    location: "",
    phone: "",
    message: "",
  });
  const [welcomeModal, setWelcomeModal] = useState(true);
  const [artistModal, setArtistModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [numTags, setNumTags] = useState(
    parseInt(localStorage.getItem("numTags")) || 3
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = artistList.slice(startIndex, endIndex);
  const old_Login_Id = localStorage.getItem('Login_Id');
  const[ loginID, setLoginID] = useState();
  const profile = localStorage.getItem("auth_role");
  const[loginRole, setLoginRole] = useState();

  useEffect(() => {
    if(loginID === null || loginID === undefined || loginID === '') {
      old_Login_Id.then(value => {
        setLoginID(value);
      });
    }
    if(loginID === null || loginID === undefined || loginID === '') {
      profile.then(value => {
        setLoginRole(value);
      });
    }
  },[loginID, loginRole]);

  const hideWelcomeModal = () => {
    setWelcomeModal(false);
  };
  useEffect(() => {
    localStorage.setItem("numTags", numTags);
  }, [numTags]);

  const handleCreateNewArtist = () => {
    setShowInputButton(true);
  };

  const hideArtistModal = () => {
    setArtistModal(false);
  };

  const fetchTags = () => {
    dispatch(profileService.getTags())
      .then((res) => {
        setTags(res.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const fatchCategories = () => {
    dispatch(categoryService.getCategories())
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const fatchCertificates = () => {
    dispatch(certificateService.getCertificates())
      .then((res) => {
        setCertifications(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  // const new_profile = profile.then((result) =>{

  // })

  useEffect(() => {
    getHomePageTemplatesData();
    fatchCategories();
    fetchTags();
    fatchCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onChange = (checkedValues, fileName) => {
  //   setCheckedValues([checkedValues]);
  //   let newData = {
  //     ...filter,
  //     filter: 1,
  //     [fileName]: checkedValues,
  //   };
  //   setFilter(newData);
  //   setFilteredData(newData);
  //   {console.warn({newData})}
  //   dataFilter(newData);
  //   window.scrollTo(100, 500);
  // };
  const onChange = (checkedValues, fileName) => {
    console.log("check", checkedValues);
    let newData = {
      ...filter,
      filter: 1,
      [fileName]: checkedValues,
      [fileName]: checkedValues,
      [fileName]: checkedValues,
      [fileName]: checkedValues,
      [fileName]: checkedValues,
    };
    setFilter(newData);
    dataFilter(newData);
    window.scrollTo(0, 0);
  };

  const dataFilter = (filter) => {
    dispatch(filterApi.filterList(filter))
      .then((res) => {
        setArtistList(res?.data?.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  useEffect(() => {
    if (artistList) {
      let artist_tags_data = [];
      artistList.forEach((artist) => {
        if (artist.tattoo_service_ids) {
          const lowercaseWords = artist?.tattoo_service_ids?.map((word) =>
            word.toLowerCase()
          );
          let dataExist = lowercaseWords
            .join(",")
            .includes(search.toLowerCase());
          if (dataExist) {
            artist_tags_data.push(artist);
          }
        }
      });

      const filterData = artistList.filter(
        (entry) =>
          entry?.name?.toLowerCase().includes(search.toLowerCase()) ||
          entry?.artist_name?.toLowerCase().includes(search.toLowerCase()) ||
          entry?.category_name?.toLowerCase().includes(search.toLowerCase()) ||
          entry?.city?.toLowerCase().includes(search.toLowerCase()) ||
          entry?.state?.toLowerCase().includes(search.toLowerCase()) ||
          entry?.country?.toLowerCase().includes(search.toLowerCase())
      );

      console.log(filterData,"filterData")

      let dataNew = filterData.concat(artist_tags_data);
      setFilterList(dataNew);
  
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const calcualteAverage = (item, index) => {
    const ratings = item?.review?.map(review => review.rating) ?? [];
    const filteredRatings = ratings.filter(rating => rating !== undefined);
    const sum = filteredRatings.reduce((acc, rating) => acc + rating, 0);
    const average = sum / filteredRatings.length || 0;

    return average;
  };


  const changeGrid = (e) => {
    setGrid(e);
  };

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };
  const removeFilter = () => {
    setSearch("");
    setFilter({});
    setFilteredData({});
    setCheckedValues([]);
    setFilteredData([]);
    window.location.reload();
  };

  const handleInput = (e) => {
    setMessageInput({ ...messageInput, [e.target.name]: e.target.value });
  };
  const handleFolder = (e) => {
    setFolderName(e.target.value);
  };

  const handleOkNew = () => {
    setNewModal(false);
  };

  const handleCancelNew = () => {
    setNewModal(false);
  };

  const handleSendMessgae = (item1) => {
    setNewModal(true);
    artistList.filter((item) =>
      item.user_id === item1 ? setArtistContent(item) : ""
    );
  };
  const handleSaveArtist = (item1) => {
    setArtistModal(true);
    setUserid(item1);
    setUserArtuistId(item1);
  };
  const saveArtistButton = () => {
    setLoading(true);
    setOpen(true);
    const values = {};
    values.user_id = user.user_id;
    values.is_default = 1;
    values.artist_id = userArtuistId;
    dispatch(saveArtist.SavedArtistDataService(values))
      .then((res) => {
        setOpen(false);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Artist Saved Successfully.",
          showLoaderOnConfirm: true,
          timer: 2000,
          buttonsStyling: false,
          customClass: {
            confirmButton: "artistRemovedBtn",
          },
        }).then(function () {
          window.location.href = "/saved-artist";
        });
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const artistListData = () => {
    let user_id = userDetails.user_id;
    setLoading(true);
    setOpen(true);
    dispatch(profileService.getArtistList(user_id))
      .then((res) => {
        setArtistList(res?.data);
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };
  const getHomePageTemplatesData = () => {
    setLoading(true);
    setOpen(true);
    dispatch(getHomePageService.getHomePageTemplateService())
      .then((res) => {
        setHomeTemplateData(res?.data);
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const [arrow, setArrow] = useState('Show');

  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const fetchCurrentUser = (user_id) => {
    setLoading(true);
    setOpen(true);
    dispatch(messageService.getUserData(user_id))
      .then((res) => {
        setUser({
          ...user,
          name: res.data.data.first_name,
          email: res.data.data.email,
          mobile: res.data.data.phone_number,
          location: res.data.data.location,
          user_id: res.data.data.user_id,
        });
        setCustomerId(res?.data?.data?.user_id);
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  useEffect(() => {
    artistListData();
    if (userDetails?.user_id) {
      fetchCurrentUser(userDetails?.user_id);
    }
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await form.validateFields(["message", "mobile"]);
      const data = {
        name: user?.first_name,
        email: user?.email,
        message: messageInput?.message,
        phone: messageInput?.phone,
        location: messageInput?.location,
        to_id: artistContent?.user_id,
        from_id: user?.user_id,
        role: userDetails.role[0] ?? userDetails?.role,
      };
      enterLoading(0);
      dispatch(messageService.sendMessage(data))
        .then((res) => {
          notification.open({
            message: "Message sent successfully!",
            icon: (
              <CheckCircleOutlined
                style={{
                  color: "#d62b47",
                }}
              />
            ),
          });
          setNewModal(false);
          window.location.reload();
        })
        .catch((err) => {
          console.info("Get Error Edit State...");
        });
    } catch {
      console.log("Validation error");
    }
  };
  const onFinishCreateArtish = async (e) => {
    setLoading(true);
    setOpen(true);
    e.preventDefault();
    try {
      await form.validateFields(["saveAartistForm", "CreateNewArtist"]);
      const values = {};
      values.user_id = user.user_id;
      values.folder_name = folderName;
      values.artist_id = userArtuistId;
      values.is_default = 0;
      enterLoading(0);
      dispatch(saveArtist.SavedArtistDataService(values))
        .then((res) => {
          setLoading(false);
          setOpen(false);
          Swal.fire({
            icon: "success",
            title: "Create Artist Saved Successfully.",
            // text: "Artist Saved Successfully.",
            showLoaderOnConfirm: true,
            timer: 2000,
          }).then(function () {
            window.location.href = "/saved-artist";
          });
        })
        .catch((err) => {
          console.info("Get Error Edit State...");
        });
    } catch {
      console.log("Validation error");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const bannerStyle = {
    backgroundImage: `url(${homeTemplateData?.template_photo})`,
    backgroundSize: "cover",
    height: "450px",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const ShowMore = (index) => {
    setShowMore({ ...showMore, [index]: false });
  };

  const unSaveArtist = (id) => {
    Swal.fire({
      title:
        "Are you sure you want to remove this artist from your saved-list?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d62b47",
      cancelButtonColor: "#fff",
      confirmButtonText: "Confirm",
      buttonsStyling: false,
      customClass: {
        confirmButton: "unsaveConfirmBtn",
        cancelButton: "unsaveCancelBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(true);
        setLoading(true);
        let artist_id = id;
        let customer_id = customerID;
        dispatch(
          profileService.removeFavouriteArtist({ artist_id, customer_id })
        )
          .then((res) => {
            let userData = { ...user };
            userData.isFav = false;
            setOpen(false);
            setLoading(false);
            setUser(userData);
            Swal.fire({
              title: "Artist removed",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "artistRemovedBtn",
              },
            }).then(function () {
              window.location.reload();
            });
          })
          .catch((err) => {
            console.info("Get Error Edit State...");
          });
      }
    });
  };

  let Key = Math.floor(Math.random() * 100000);

  return (
    <div className="homeWrapper">
      <div className="homeInner">
        <div className="bannerWrapper">
          <div className="innerWrap">
            <div className="bannerSec" style={bannerStyle}>
              <div className="bannerContent">
                <h3 className="secTitle">
                  {/* Discover the <span className="colorText">Tattoo Artist</span> */}
                  {homeTemplateData?.template_name}
                </h3>
                <p className="subText">
                  {/* Neque porro quisquam est qui dolorem ipsum quia dolor sit
                  amet. */}
                  {homeTemplateData?.sub_heading}
                </p>
                <div className="searchBar">
                  <Input
                    placeholder="Search keywords here..."
                    className="formControl"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contentWrap">
          <div className="container-fluid">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col md={24} className="text-end mb-3">
                <div className="toggleSection text-end">
                  <Segmented
                    options={[
                      {
                        value: "List",
                        icon: <AiOutlineBars />,
                      },
                      {
                        value: "Kanban",
                        icon: <AiOutlineAppstore />,
                      },
                    ]}
                    onChange={(e) => changeGrid(e)}
                  />
                </div>
              </Col>
              <Col xs={24} md={6} lg={6}>
                <div className="sidebarWrapper">
                  <Card
                    className="sidebarCard"
                    title="Filters"
                    bordered={false}
                  >
                    <div className="cardInner">
                      <div className="filters">
                        <div className="formGroup">
                          <label className="filterLabel">Location</label>
                          <Input
                            placeholder="Search City and State"
                            className="formControl"
                            // value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                        <Collapse
                          bordered={false}
                          //defaultActiveKey={['1', '6']}
                          className="filterDrop"
                          expandIcon={({ isActive }) => (
                            <AiOutlineDown rotate={isActive ? 180 : 0} />
                          )}
                          expandIconPosition="end"
                        >
                          <Panel header="Categories" key="1">
                            <Checkbox.Group
                              name="category_name"
                              //value={checkedValues}
                              options={category}
                              onChange={(options) =>
                                onChange(options, "suggested_filter")
                              }
                            />
                          </Panel>
                          <Panel header="Style" key="2">
                            <Checkbox.Group
                              name="tattoo_service_ids"
                              options={tags}
                              // value={checkedValues}
                              onChange={(options) =>
                                onChange(options, "categories")
                              }
                            />
                          </Panel>
                          <Panel header="Job Cost" key="3">
                            <Radio.Group
                              // value={checkedValues}
                              onChange={(e) =>
                                onChange(e.target.value, "price_range")
                              }
                              name="price_range"
                            >
                              <Space direction="vertical">
                                <Radio value={"0-499"}>$0 - $499</Radio>
                                <Radio value={"500-2999"}>$500 - $2999</Radio>
                                <Radio value={"3000-9999"}>$3000 - $9999</Radio>
                              </Space>
                            </Radio.Group>
                          </Panel>
                          <Panel header="Awards" key="4">
                            <Checkbox.Group
                              // value={checkedValues}
                              name="certificates"
                              onChange={(e) => onChange(e, "certificates")}
                            >
                              {certifications?.map((certificate, index) => {
                                return (
                                  <Checkbox value={certificate?.id} key={index}>
                                    {certificate?.certificate_name}
                                  </Checkbox>
                                );
                              })}
                            </Checkbox.Group>
                          </Panel>
                          {/* <Panel header="Features" key="5">
                            <Checkbox onChange={onChange}>Lorem Ipsum</Checkbox>
                          </Panel> */}
                          <Panel header="Rating" key="5">
                            <Radio.Group
                            //  value={checkedValues}
                              onChange={(e) =>
                                onChange(e.target.value, "reviews")
                              }
                              name="reviews"
                            >
                              <Space direction="vertical">
                                <Radio value={5}>
                                  <Rate disabled defaultValue={5} /> Only
                                </Radio>
                                <Radio value={4}>
                                  <Rate disabled defaultValue={4} /> & Up
                                </Radio>
                                <Radio value={3}>
                                  <Rate disabled defaultValue={3} /> & Up
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Panel>
                        </Collapse>
                      </div>
                      <IconButton
                        icon={<FaFilter />}
                        text="Clear Filter"
                        type="btn"
                        handleClick={removeFilter}
                      />
                    </div>
                  </Card>
                </div>
              </Col>
              <Col xs={24} md={18} lg={18}>
                <div className="listingWrapper">
                  {loading ? (
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={open}
                    >
                      <Spin />
                    </Backdrop>
                  ) : (
                    <div className="liistWrapper">
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                        {search && filterList.length === 0 ? (
                          <div className="text-center w-100">
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={<span>No result found.</span>}
                            />
                          </div>
                        ) : filterList && search ? (
                          filterList?.map((item, index) => {
                            return (
                              <>
                                {grid === "List" ? (
                                  <Card
                                    bordered={false}
                                    className="profileCard mb-3"
                                    key={index}
                                  >
                                    <div className="cardContent">
                                      <div className="innerContent">
                                        <div className="cardCoverImg">
                                          <Image
                                            src={CardCover}
                                            preview={false}
                                            className="img-fluid"
                                            alt="card image"
                                          />
                                        </div>
                                        <div className="profileDetails">
                                          <div className="artistRate">
                                            <Rate
                                              disabled
                                              key={Key}
                                              defaultValue={calcualteAverage(item, index)}
                                            />{" "}
                                            <span className="small">
                                              ({item?.review.length} Reviews)
                                            </span>
                                          </div>
                                          <div className="artistProfile">
                                            <Avatar
                                              className="artistImg"
                                              src={
                                                item?.profile_photo_id
                                                  ? item.profile_photo_id
                                                  : AvatarImg
                                              }
                                            />
                                            <Link
                                              to={
                                                "/user-profile/?user_id=" +
                                                item?.user_id
                                              }
                                              className="artistName"
                                            >
                                              {item?.artist_name}
                                            </Link>
                                          </div>
                                          {item?.plan_id === 1 ||
                                          item?.plan_id === 2 ? (
                                            <Badge.Ribbon
                                              className="badgePre"
                                              text="Premium"
                                              color="green"
                                            ></Badge.Ribbon>
                                          ) : item?.plan_id === 3 ||
                                            item?.plan_id === 4 ? (
                                            <Badge.Ribbon
                                              className="badgePre"
                                              text="Pro"
                                              color="volcano"
                                            ></Badge.Ribbon>
                                          ) : (
                                            item?.plan_id === 5 && ""
                                          )}
                                          <p className="address">
                                            <IoLocationOutline />{" "}
                                            {item?.city ? item?.city : ""},{" "}
                                            {item?.state}, {item?.country}
                                          </p>
                                          <Divider />
                                          <div className="tagSection">
                                            <Space size={[0, 8]} wrap>
                                              {item?.tattoo_service_ids?.map(
                                                (catLabel, i) => {
                                                  return (
                                                    <Tag key={i}>
                                                      {catLabel}
                                                    </Tag>
                                                  );
                                                }
                                              )}
                                            </Space>
                                          </div>
                                          <div className="profileBtn text-end">
                                            <Link
                                              to={
                                                "/user-profile/?user_id=" +
                                                item?.user_id
                                              }
                                              className="profileLink"
                                            >
                                              View Profile
                                            </Link>
                                          </div>
                                        </div>
                                        {userDetails?.token ? (
                                          <>
                                            {userDetails &&
                                            userDetails?.role &&
                                            userDetails?.role.length > 0 &&
                                            userDetails?.role[0] ===
                                              "Customer" ? (
                                              <div className="btnSection">
                                                <IconButton
                                                  type="primary"
                                                  handleClick={() =>
                                                    handleSendMessgae(
                                                      item?.user_id
                                                    )
                                                  }
                                                  text="Send Message"
                                                  className="searchBtn w-auto me-0 mb-3"
                                                  icon={<FaRegEnvelope />}
                                                />
                                                <IconButton
                                                  type="primary"
                                                  handleClick={() =>
                                                    handleSaveArtist(
                                                      item?.user_id
                                                    )
                                                  }
                                                  text="Save Artist"
                                                  className="searchBtn w-auto"
                                                  icon={<FaRegBookmark />}
                                                />
                                              </div>
                                            ) : (
                                              <div className="btnSection">
                                                <IconButton
                                                  type="primary"
                                                  handleClick={() =>
                                                    handleSendMessgae(
                                                      item?.user_id
                                                    )
                                                  }
                                                  text="Send Message"
                                                  className="searchBtn w-auto me-0 mb-3"
                                                  icon={<FaRegEnvelope />}
                                                />
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Card>
                                ) : (
                                  <Col
                                    xs={24}
                                    md={8}
                                    key={index}
                                    className="mb-4"
                                  >
                                    <Card
                                      key={index}
                                      className="gridCard"
                                      cover={
                                        <img
                                          className="cardCoverImg"
                                          alt="example"
                                          src={CardCover}
                                        />
                                      }
                                    >
                                      <Meta
                                        avatar={
                                          <Avatar
                                            src={
                                              item?.profile_photo_id
                                                ? item.profile_photo_id
                                                : AvatarImg
                                            }
                                          />
                                        }
                                        title={
                                          <Link
                                            to={
                                              "/user-profile/?user_id=" +
                                              item?.user_id
                                            }
                                            className="artistName"
                                          >
                                            {item?.artist_name}
                                          </Link>
                                        }
                                        description={
                                          <>
                                            <Rate
                                              disabled
                                              defaultValue={
                                                item?.review &&
                                                item?.review.length === 0
                                                  ? 0
                                                  : item?.review[0].rating
                                              }
                                            />{" "}
                                            <span className="small">
                                              ({item?.review.length} Reviews)
                                            </span>
                                            <p className="address">
                                              <IoLocationOutline />{" "}
                                              {item?.city ? item?.city : ""},{" "}
                                              {item?.state}, {item?.country}
                                            </p>
                                            <Space size={[0, 8]} wrap>
                                              {item?.tattoo_service_ids?.map(
                                                (catLabel, i) => {
                                                  return (
                                                    <Tag key={i}>
                                                      {catLabel}
                                                    </Tag>
                                                  );
                                                }
                                              )}
                                            </Space>
                                          </>
                                        }
                                      />
                                      <Row gutter={16}>
                                        <Col xs={24} md={12}></Col>
                                        <Col xs={24} md={12}>
                                          {item?.plan_id === 1 ||
                                          item?.plan_id === 2 ? (
                                            <Badge.Ribbon
                                              className="badgePre"
                                              text="Premium"
                                              color="green"
                                            ></Badge.Ribbon>
                                          ) : item?.plan_id === 3 ||
                                            item?.plan_id === 4 ? (
                                            <Badge.Ribbon
                                              className="badgePre"
                                              text="Pro"
                                              color="volcano"
                                            ></Badge.Ribbon>
                                          ) : (
                                            item?.plan_id === 5 && ""
                                          )}
                                        </Col>
                                      </Row>
                                      <Divider />
                                      <Row
                                        gutter={16}
                                        className="d-flex mt-2"
                                        style={{ justifyContent: "center" }}
                                      >
                                        {userDetails?.role &&
                                        userDetails?.role[0] === "Customer" ? (
                                          <>
                                            <Col xs={24} md={8}>
                                              <FaRegEnvelope
                                                key="message"
                                                onClick={() =>
                                                  handleSendMessgae(
                                                    item?.user_id
                                                  )
                                                }
                                              />
                                            </Col>
                                            <Col xs={24} md={8}>
                                              <FaRegBookmark
                                                key="saved"
                                                onClick={() =>
                                                  handleSaveArtist( item?.user_id)
                                                }
                                                title="Saved Artist"
                                              />
                                            </Col>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {userDetails?.role &&
                                        userDetails?.role[0] === "Artist" ? (
                                          <>
                                            <Col xs={24} md={16}>
                                              <FaRegEnvelope
                                                key="message"
                                                onClick={() =>
                                                  handleSendMessgae(
                                                    item?.user_id
                                                  )
                                                }
                                              />
                                            </Col>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <Col xs={24} md={8}>
                                          <Link
                                            to={
                                              "/user-profile/?user_id=" +
                                              item?.user_id
                                            }
                                            className="profileLink"
                                            style={{
                                              textDecoration: "none",
                                              color: "red",
                                              textAlign: "center",
                                            }}
                                          >
                                            View Profile
                                          </Link>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                )}
                              </>
                            );
                          })
                        ) : (
                          currentItems?.map((item, index) => {
                            return (
                              <>
                              {console.info({artistList})}
                                {item?.id === null || item?.name === null ? (
                                  ""
                                ) : (
                                  <>
                                    {grid === "List" ? (
                                      <Card
                                        bordered={false}
                                        className="profileCard mb-3"
                                        key={index}
                                      >
                                        <div className="cardContent">
                                          <div className="innerContent">
                                            <div className="cardCoverImg">
                                              {console.info("SDfsdfsfsdfsd",item)}
                                              {item && item?.slider_media_id && Array.isArray(item.slider_media_id) &&                                              item.slider_media_id.length > 0 ? (
                                              <Carousel autoplay>
                                                {item.slider_media_id.map((slide, index) => (
                                                  <div className="slides slide-images" key={index}>
                                                    <Image
                                                      preview={false}
                                                      src={slide?.url || CardCover}
                                                      alt="slides"
                                                    />
                                                  </div>
                                                ))}
                                              </Carousel>
                                              ) : ( 
                                                <Image
                                                  src={CardCover}
                                                  preview={false}
                                                  className="img-fluid"
                                                  alt="card image"
                                                />
                                              )}
                                            </div>
                                            <div className="profileDetails">
                                              <div className="artistRate">
                                                <Rate
                                                  disabled
                                                  key={Key}
                                                  defaultValue={calcualteAverage(item, index)}
                                                />{" "}
                                                <span className="small">
                                                  ({item?.review.length}{" "}
                                                  Reviews)
                                                </span>
                                              </div>
                                              <div className="artistProfile">
                                                <Avatar
                                                  className="artistImg"
                                                  src={
                                                    item?.profile_photo_id
                                                      ? item.profile_photo_id
                                                      : AvatarImg
                                                  }
                                                />
                                                <Link
                                                  to={
                                                    "/user-profile/?user_id=" +
                                                    item?.user_id
                                                  }
                                                  className="artistName"
                                                >
                                                  {item?.artist_name}
                                                </Link>
                                              </div>

                                              <p className="address">
                                                <IoLocationOutline />{" "}
                                                {item?.city ? item?.city : ""},{" "}
                                                {item?.state}, {item?.country}
                                              </p>
                                              <Row gutter={16}>
                                                <Col xs={24} md={12}></Col>
                                                <Col xs={24} md={12}>
                                                  {item?.plan_id === 1 ||
                                                  item?.plan_id === 2 ? (
                                                    <Badge.Ribbon
                                                      className="badgePre"
                                                      text="Premium"
                                                      color="green"
                                                    ></Badge.Ribbon>
                                                  ) : item?.plan_id === 3 ||
                                                    item?.plan_id === 4 ? (
                                                    <Badge.Ribbon
                                                      className="badgePre"
                                                      text="Pro"
                                                      color="volcano"
                                                    ></Badge.Ribbon>
                                                  ) : (
                                                    item?.plan_id === 5 && ""
                                                  )}
                                                </Col>
                                              </Row>
                                              <Divider />
                                              <div className="tagSection">
                                                <Space size={[0, 8]} wrap>
                                                  {item?.tattoo_service_ids?.map(
                                                    (catLabel, i) => {
                                                      return (
                                                        <Tag key={i}>
                                                          {catLabel}
                                                        </Tag>
                                                      );
                                                    }
                                                  )}
                                                </Space>
                                              </div>
                                              <div className="profileBtn text-end">
                                                <Link
                                                  to={
                                                    "/user-profile/?user_id=" +
                                                    item?.user_id
                                                  }
                                                  className="profileLink"
                                                >
                                                  View Profile
                                                </Link>
                                              </div>
                                            </div>
                                            {userDetails &&
                                            userDetails?.role &&
                                            // userDetails?.role.length > 0 &&
                                            loginRole ===
                                              "Customer" ? (
                                              <div className="btnSection">
                                                <IconButton
                                                  type="primary"
                                                  handleClick={() =>
                                                    handleSendMessgae(
                                                      item?.user_id
                                                    )
                                                  }
                                                  text="Send Message"
                                                  className="searchBtn w-auto me-0 mb-3"
                                                  icon={<FaRegEnvelope />}
                                                />
                                                {item?.saved_artist == 1 ? (
                                                  <IconButton
                                                    type="primary"
                                                    handleClick={() =>
                                                      unSaveArtist(
                                                        item?.user_id
                                                      )
                                                    }
                                                    text="Saved"
                                                    className="SavedArtistBtn w-auto"
                                                    icon={<FaRegBookmark />}
                                                  />
                                                ) : (
                                                  <IconButton
                                                    type="primary"
                                                    handleClick={() =>
                                                      handleSaveArtist(
                                                        item?.user_id
                                                      )
                                                    }
                                                    text="Save Artist"
                                                    className="searchBtn w-auto"
                                                    icon={<FaRegBookmark />}
                                                  />
                                                )}
                                              </div>
                                            ) : loginRole ? (
                                              <>
                                                <div className="btnSection">
                                                  {/* <IconButton
                                                type="primary"
                                                handleClick={() =>
                                                  handleSendMessgae(
                                                    item?.user_id
                                                  )
                                                }
                                                text="Send Message"
                                                className="searchBtn w-auto me-0 mb-3"
                                                icon={<FaRegEnvelope />}
                                              /> */}
                                                  <IconButton
                                                    type="primary"
                                                    handleClick={() =>
                                                      handleSendMessgae(
                                                        item?.user_id
                                                      )
                                                    }
                                                    text="Send Message"
                                                    className="searchBtn w-auto me-0 mb-3"
                                                    icon={<FaRegEnvelope />}
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </Card>
                                    ) : (
                                      <Col
                                        xs={24}
                                        md={8}
                                        key={index}
                                        className="mb-4"
                                      >
                                        <Card
                                          key={index}
                                          className="gridCard"
                                          cover={
                                            <img
                                              className="cardCoverImg"
                                              alt="example"
                                              src={CardCover}
                                            />
                                          }
                                        >
                                          <Meta
                                            avatar={
                                              <Avatar
                                                src={
                                                  item?.profile_photo_id
                                                    ? item.profile_photo_id
                                                    : AvatarImg
                                                }
                                              />
                                            }
                                            title={
                                              <Link
                                                to={
                                                  "/user-profile/?user_id=" +
                                                  item?.user_id
                                                }
                                                className="artistName"
                                              >
                                                {item?.artist_name}
                                              </Link>
                                            }
                                            description={
                                              <>
                                                <Rate
                                                  disabled
                                                  defaultValue={
                                                    item?.review &&
                                                    item?.review.length === 0
                                                      ? 0
                                                      : item?.review[0].rating
                                                  }
                                                />{" "}
                                                <span className="small">
                                                  ({item?.review.length}{" "}
                                                  Reviews)
                                                </span>
                                                <p className="address">
                                                  <IoLocationOutline />{" "}
                                                  {item?.city ? item?.city : ""}
                                                  , {item?.state},{" "}
                                                  {item?.country}
                                                </p>
                                                <Space size={[0, 8]} wrap>
                                                  <>
                                                    {showMore[item.id] && (
                                                      <>
                                                        {item.tattoo_service_ids
                                                          ?.slice(numTags)
                                                          .map(
                                                            (catLabel, i) => (
                                                              <Tag key={i}>
                                                                {catLabel}
                                                              </Tag>
                                                            )
                                                          )}
                                                      </>
                                                    )}
                                                    {item.tattoo_service_ids
                                                      ?.length > numTags && (
                                                      <tag
                                                        onClick={() =>
                                                          setShowMore({
                                                            ...showMore,
                                                            [item.id]:
                                                              !showMore[
                                                                item.id
                                                              ],
                                                          })
                                                        }
                                                      >
                                                        {showMore[item.id]
                                                          ? "Show Less"
                                                          : "Show More"}
                                                      </tag>
                                                    )}
                                                  </>
                                                </Space>
                                              </>
                                            }
                                          />
                                          <Row gutter={16}>
                                            <Col xs={24} md={12}></Col>
                                            <Col xs={24} md={12}>
                                              {item?.plan_id === 1 ||
                                              item?.plan_id === 2 ? (
                                                <Badge.Ribbon
                                                  className="badgePre"
                                                  text="Premium"
                                                  color="green"
                                                ></Badge.Ribbon>
                                              ) : item?.plan_id === 3 ||
                                                item?.plan_id === 4 ? (
                                                <Badge.Ribbon
                                                  className="badgePre"
                                                  text="Pro"
                                                  color="volcano"
                                                ></Badge.Ribbon>
                                              ) : (
                                                item?.plan_id === 5 && ""
                                              )}
                                            </Col>
                                          </Row>
                                          <Divider />
                                          <Row
                                            gutter={16}
                                            className="d-flex mt-2"
                                            style={{ justifyContent: "center" }}
                                          >
                                            {userDetails?.role &&
                                            userDetails?.role[0] ===
                                              "Customer" ? (
                                              <>
                                              <Tooltip style={{cursor:"pointer"}} placement="topLeft" title={"Message"} arrow={mergedArrow}>
                                              <Col xs={24} md={8}>
                                                  <FaRegEnvelope style={{cursor: "pointer"}}
                                                    key="message"
                                                    onClick={() =>
                                                      handleSendMessgae(
                                                        item?.user_id
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                </Tooltip>
                                                {item?.saved_artist == 1 ? 
                                                <Tooltip placement="topLeft" title={"Saved"} arrow={mergedArrow}>
                                                <Col xs={24} md={8}>
                                                  <FaBookmark style={{cursor: "pointer"}}
                                                    key="saved"
                                                    onClick={() =>
                                                      unSaveArtist(item?.user_id)
                                                    }
                                                  />
                                                </Col>
                                                </Tooltip>
                                                : <Tooltip placement="topLeft" title={"Saved Artist"} arrow={mergedArrow}>
                                                <Col xs={24} md={8}>
                                                  <FaRegBookmark style={{cursor: "pointer"}}
                                                    key="saved"
                                                    onClick={() =>
                                                      handleSaveArtist(item?.user_id)
                                                    }
                                                  />
                                                </Col>
                                                </Tooltip>}
                                               
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {userDetails?.role &&
                                            userDetails?.role[0] ===
                                              "Artist" ? (
                                              <>
                                                <Col xs={24} md={16}>
                                                  <FaRegEnvelope
                                                    key="message"
                                                    onClick={() =>
                                                      handleSendMessgae(
                                                        item?.user_id
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            <Col xs={24} md={8}>
                                              <Link
                                                to={
                                                  "/user-profile/?user_id=" +
                                                  item?.user_id
                                                }
                                                className="profileLink"
                                                style={{
                                                  textDecoration: "none",
                                                  color: "red",
                                                  textAlign: "center",
                                                }}
                                              >
                                                View Profile
                                              </Link>
                                            </Col>
                                          </Row>
                                        </Card>
                                      </Col>
                                    )}
                                  </>
                                )}
                              </>
                            );
                          })
                        )}
                      </Row>
                      <Row>
                        <div className="paginationWrapper">
                          <Pagination
                            current={currentPage}
                            pageSize={itemsPerPage}
                            total={artistList.length}
                            onChange={handlePageChange}
                          />
                        </div>
                      </Row>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <Modal
            title="Connect with artist 2"
            open={isNewModal}
            onOk={handleOkNew}
            onCancel={handleCancelNew}
            footer={false}
            className="formModal"
            width={780}
          >
            <div className="modalBody">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col xs={12} md={24}>
                  <div className="toSection">
                    <p className="secLable">
                      <label>To:</label>
                    </p>
                    <div className="toWrap">
                      <div className="imgSec">
                        <Image
                          src={
                            artistContent?.media_url
                              ? artistContent?.media_url
                              : Logo
                          }
                          className="img-fluid"
                          preview={false}
                          alt="image"
                        />
                      </div>
                      <div className="detailsWrap">
                        <p className="toName">{artistContent?.artist_name}</p>
                        <Rate
                          disabled
                          defaultValue={
                            artistContent?.rating ? artistContent?.rating : "0"
                          }
                        />{" "}
                        <span className="small">
                          {artistContent?.review_count
                            ? artistContent?.review_count
                            : "0"}{" "}
                          reviews
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col xs={24} md={24}>
                  <Form
                    name="messageForm"
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    ref={formRef}
                  >
                    <Row gutter={16}>
                      <Col xs={24} md={12}>
                        <Form.Item name="name" label="Name">
                          <Input
                            name="name"
                            placeholder="Name"
                            className="formControl"
                            defaultValue={user?.name}
                            onChange={handleInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item name="email" label="Email">
                          <Input
                            name="email"
                            placeholder="Email"
                            className="formControl"
                            defaultValue={user?.email}
                            onChange={handleInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item name="location" label="Location">
                          <Input
                            name="location"
                            placeholder="Location"
                            className="formControl"
                            defaultValue={messageInput.location}
                            onChange={handleInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="mobile"
                          label="Mobile No."
                          rules={[
                            {
                              required: true,
                              message: "Please enter mobile number here!",
                            },
                            {
                              pattern: /^[\d]{0,15}$/,
                              message: "Enter valid mobile number.",
                            },
                            {
                              maxLength: 15,
                              message: "Enter valid mobile number.",
                            },
                          ]}
                        >
                          <Input
                            name="phone"
                            placeholder="Mobile No."
                            className="formControl"
                            defaultValue={messageInput.phone}
                            onChange={handleInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24}>
                        <Form.Item
                          name="message"
                          label="Message"
                          rules={[
                            {
                              required: true,
                              message: "Message field required",
                            },
                            {
                              type: "string",
                              warningOnly: true,
                            },
                          ]}
                        >
                          <Input.TextArea
                            rows={5}
                            maxLength={2000}
                            placeholder="Message"
                            className="formControl"
                            name="message"
                            defaultValue={messageInput.message}
                            onChange={handleInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24}>
                        <div className="privacyLinkRow">
                          <p>
                            By clicking or tapping "Send", I agree to the
                            Finding Ink{" "}
                            <Link to={"/terms-and-condition"} className="link">
                              Terms of Use
                            </Link>
                            . The Finding Ink{" "}
                            <Link to={"/privacy-policy"} className="link">
                              Privacy Policy
                            </Link>{" "}
                            applies.
                          </p>
                        </div>
                      </Col>
                      <Col xs={24} md={24}>
                        <Form.Item className="text-end">
                          <SecondaryButton
                            type="primary"
                            handleClick={handleCancelNew}
                            text="Cancel"
                            className="w-auto"
                          />
                          <Button
                            type="primary"
                            onClick={(e) => handleSubmit(e)}
                            loading={loadings[0]}
                            className="submitBtn w-auto"
                          >
                            Send
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Modal>
          {userDetails &&
          userDetails?.role &&
          userDetails?.role.length > 0 &&
          userDetails?.role[0] === "Customer" ? (
            <>
              {welcomeModal && (
                <Modal
                  open={welcomeModal}
                  onOk={hideWelcomeModal}
                  onCancel={hideWelcomeModal}
                  footer={false}
                  className="welcomeModal"
                  afterClose={setWelcomeModal(false)}
                >
                  <div className="modalBody">
                    <Result
                      icon={<SmileOutlined />}
                      title={"Welcome" + +userDetails?.name}
                      subTitle="Let's explore your tattoo imaginaton and connect you with artists."
                      extra={
                        <Button type="primary" onClick={hideWelcomeModal}>
                          Explore Finding Ink
                        </Button>
                      }
                    />
                  </div>
                </Modal>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <Modal
        title="Save Artist"
        open={artistModal}
        onOk={hideArtistModal}
        onCancel={hideArtistModal}
        footer={false}
        className="formModal"
        width={580}
      >
        <div className="modalBody">
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
            justify={"space-around"}
          >
            <Col xs={24} md={24}>
              <Form
                name="savaArtistForm"
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
                form={form}
              >
                <Row gutter={16} justify="center" align={"center"}>
                  <Col xs={12} md={6}>
                    <div className="circleButton">
                      <div className="roleIcon">
                        <Link to={""} onClick={saveArtistButton}>
                          <FaRegBookmark className="saveIcon" key="saved" />
                        </Link>
                      </div>
                      <h6 className="roleTitle">Save Artist</h6>
                    </div>
                  </Col>
                  <Col xs={12} md={6} display={"flex"}>
                    <div className="circleButton">
                      <div className="roleIcon">
                        <Link
                          to={""}
                          onClick={() =>
                            handleCreateNewArtist(!showInputButton)
                          }
                          className="roleLink"
                        >
                          <FaPlus className="saveIcon" />
                        </Link>
                      </div>
                      <h6 className="roleTitle">Create New Album</h6>
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                    </div>
                  </Col>
                </Row>
                {showInputButton === true ? (
                  <>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                      <Col xs={24} md={24}>
                        <Form.Item
                          name="CreateNewArtist"
                          rules={[
                            {
                              required: true,
                              message: "Please create new artist",
                            },
                          ]}
                        >
                          <Input
                            name="CreateNewArtist"
                            placeholder="Create New Artist"
                            className="formControl"
                            onChange={handleFolder}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                      <Col xs={24} md={24}>
                        <Form.Item className="text-center">
                          <Button
                            type="primary"
                            onClick={(e) => onFinishCreateArtish(e)}
                            loading={loadings[0]}
                            className="submitBtn w-auto"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
