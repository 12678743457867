import React, {useState, useEffect} from "react";
import { Table, Spin } from 'antd';
import { IconButton } from '../components/Buttons/buttons'
import { AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as messagesService from '../services/sendMessage';
import moment from "moment";

const InboxData = (props) => {
    console.info("TEST",props);
    let old_userId = props?.user;
    const userId = parseInt(old_userId);
    console.info("CHEKCING",userId)
    var dispatch = useDispatch();
    let navigate = useNavigate();
    const [ loading, setLoading ] = useState(true);
    const [ messages, setMessages ] = useState([]);
    const userDetails = useSelector((state) => state?.User?.userDetails);

    const getMessages = (user_id) => {
        dispatch(messagesService.getMessagesCustomer(user_id))
        .then((res) => {
            setLoading(true);
            setMessages(res.data.data);
            setLoading(false);
        })
        .catch((err) => {
            console.info({err});
        })
    };

    const changeStatusMsg = (record) => {
        const data = {
            from_id : record.from_id,
            to_id: record.to_id
        }
        dispatch(messagesService.changeStatus(data))
        .then((res) => {
            navigate('/inbox/view-message?from_id='+record.from_id +'&to_id=' +record.to_id +'&name='+record.name, { state:{userId}} )
        })
        .catch((err) => {
            console.warn({err});
        })
    }

    useEffect(() => {
        getMessages(userDetails?.user_id)
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: ((text, record) => {
                return(
                    <>
                        {moment(record?.date).format("MM-DD-YYYY")}
                    </>
                )
            }),
            sorter: (a, b) => {
                a = a.date || "";
                b = b.date || "";
                return a.localeCompare(b);
            },
        },
        {
            title: "Customer Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => {
                a = a.name || "";
                b = b.name || "";
                return a.localeCompare(b);
            },
            // sorter: {
            //     compare: (a, b) => a.name - b.name,
            // },
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => {
                a = a.email || "";
                b = b.email || "";
                return a.localeCompare(b);
            },
        },
        {
            title: "Phone Number",
            dataIndex: "phone",
            key: "phone",
            render: ((text, record) => {
                return(
                    <>
                        {record?.phone ? record?.phone : '-'}
                    </>
                )
            }),
            sorter: (a, b) => {
                a = a.phone || "";
                b = b.phone || "";
                return a.localeCompare(b);
            },
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            render: ((text, record) => {
                return(
                    <>
                        {record?.location ? record?.location : '-'}
                    </>
                )
            })
        },
        {
            title: "Messages",
            dataIndex: "action",
            key: "action",
            render: (index, record) => {
                return (
                    <div className='actionBtn'>
                        <IconButton
                            text="View Message"
                            handleClick={() => changeStatusMsg(record)}
                            icon={<AiOutlineEye />}
                            id={index}
                            className={record.status === 1 ? 'seenMsg w-auto' : 'w-auto'}
                        />
                    </div>
                )
            },
        },
    ];
    

    return (
        <div className='tableWrapper'>
            <Table
                className='inboxDatatable'
                tableLayout="auto"
                bordered={false}
                dataSource={messages}
                columns={columns}
                pagination={false}
                rowKey={(record) => record.messages}
                loading={{
                    indicator: <Spin size="large" />,
                    spinning: loading,
                }}
                showSorterTooltip={false}
        />
        </div>
    );
}

export default InboxData;