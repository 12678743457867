import axios from 'axios';
import { TATTOO_URL } from "./Helper";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = TATTOO_URL;

axios.interceptors.response.use(
  (response) => {
    //console.warn({ response });
    return response;
  },
  (error) => {
    //console.warn(error);
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function (config) {
  
  const token = `Bearer ${localStorage.getItem('auth_token')}`;
  //console.warn({config})
  config.headers.Authorization = token;
  return config;
});
export default axios;
