import React, { useEffect, useState } from 'react';
import { Row, Col, Collapse, Spin } from 'antd';
// import { PageBanner } from '../../components/Banner/pageBanner';
import { AiOutlineDown } from "react-icons/ai";
import * as faqService from '../../services/faqService';
import { useDispatch } from "react-redux";
import * as getFaqTemplate from "../../services/getFaqTemplate";


const Faqs = () => {
    const { Panel } = Collapse;
    var dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [FaqList, setFaqList] = useState([]);
    const [faqData, setFaqData] = useState([]);
    const bannerStyle = {
        backgroundImage: `url(${faqData?.template_photo})`,
        backgroundSize: 'cover',
        height: '450px',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
    const getFaq = () => {
        dispatch(faqService.getFaq())
            .then((res) => {
                setLoading(true);
                console.info(res);
                setFaqList(res?.data?.data);
                setLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    }
    const getFaqTemplatesData = () => {
        dispatch(getFaqTemplate.getFaqTemplateService())
        .then((res) => {
            setLoading(true);
            console.info(res);
            setFaqData(res?.data);
            setLoading(false);
        })
        .catch((err) => {
            console.info("Get Error Edit State...");
        });
    }
    
    useEffect(() => {
        getFaq();
        getFaqTemplatesData();
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log({FaqList})
    console.log({faqData});


    return (
        <div className='faqWrapper'>
            <div className='faqInnerWrap'>
            <div className='bannerWrapper'>
            <div className='innerWrap'>
                <div className='bannerSec' style={bannerStyle}>
                    <div className='bannerContent'>
                        <h3 className='secTitle'>{faqData?.template_name}
                         {/* <span className='colorText'>{colorText}</span> */}
                         </h3>
                        <p className='subText'>{faqData?.sub_heading}</p>
                    </div>
                </div>
            </div>
        </div>
                <div className='container-fluid'>
                    {loading ?
                        (
                            <div className='spinDiv'>
                                <Spin size='large' />
                            </div>
                        ) :
                        (
                            <div className='faqWrap'>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                                    <Col xs={24} md={8} xl={8}>
                                        <div className='leftWrapper'>
                                            <h3 className='leftSecTitle'>We’re here to help you</h3>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={16} xl={16}>
                                        <div className='rightWrapper'>
                                            <p className='rightSecDesc'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde ut optio laboriosam vitae
                                                ratione iure blanditiis ipsa harum delectus praesentium soluta autem, repellat voluptatem.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify="center">
                                    <Col xs={24} md={16} xl={14}>
                                        <div className='faqSection'>
                                            <Collapse
                                                bordered={false}
                                                defaultActiveKey={['1']}
                                                className="filterDrop"
                                                expandIcon={({ isActive }) => <AiOutlineDown rotate={isActive ? 180 : 0} />}
                                                expandIconPosition="end"
                                            >
                                                {FaqList?.map((list, index) => {
                                                    return(
                                                        <Panel header={list?.question} key={index}>
                                                            <p>
                                                                {list?.description}
                                                            </p>
                                                        </Panel>
                                                    )
                                                })}
                                                
                                            </Collapse>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
}

export default Faqs;