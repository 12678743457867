import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Checkbox,
  Form,
  Input,
  Button,
  notification,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components/Buttons/buttons";
import { BGImg } from "../../constant/images";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import * as loginService from "../../services/loginService";
import { useDispatch } from "react-redux";
import * as Actions from "../../store/actions/user";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { TATTOO_URL } from "../../Helper";

const Login = () => {
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [loadings, setLoadings] = useState([]);
  const [loginUrl, setLoginUrl] = useState(null);
  const [googleUrl, setGoogleUrl] = useState(null);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  useEffect(() => {
    fetch(TATTOO_URL + "api/auth/facebook", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setLoginUrl(data.url))
      .catch((error) => console.error(error));

    fetch(TATTOO_URL + "api/auth/google", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setGoogleUrl(data.url))
      .catch((error) => console.error(error));
  }, []);

  const handleNavigate = () => {
    navigate("/register");
  };

  const [loginInput, setLoginInput] = useState({
    email: "",
    password: "",
  });

  const handleInput = (e) => {
    console.warn(e.target);
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
    console.warn({ loginInput });
  };

  const onFinish = (e) => {
    enterLoading(0);
    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };
    dispatch(loginService.login(data))
      .then((res) => {
        console.log(res,"ress")
        if (res.success === true) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_role", res.data.role);
          localStorage.setItem("Login_Id", res?.data?.user_id);
          dispatch(Actions.set_user_auth(res));
          notification.open({
            message: "Successfully logged in!",
            icon: (
              <CheckCircleOutlined
                style={{
                  color: "#d62b47",
                }}
              />
            ),
          });
          navigate("/");
        } else if (res.status === '0') {
          notification.open({
            message: "Your account is deactivated by the admin!",
            icon: (
              <CloseCircleOutlined
                style={{
                  color: "#d62b47",
                }}
              />
            ),
          });
          console.error(res);
        }else {
          notification.open({
            message: "Invalid credentials!",
            icon: (
              <CloseCircleOutlined
                style={{
                  color: "#d62b47",
                }}
              />
            ),
          });
          console.error(res);
        }
      })
      .catch((err) => {
        notification.error({
          message: "Invalid credentials",
        });
        console.info("Get Error Edit State...");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const leftSectionStyle = {
    backgroundImage: `url(${BGImg})`,
    backgroundSize: "cover",
    height: "100%",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div className="authWrapper">
      <div className="authWrapInner">
        <div className="container-fluid">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify="center">
            <Col xs={24} md={24} xl={16}>
              <Card bordered={false} className="authCard">
                <Row>
                  <Col xs={24} md={12}>
                    <div className="leftSection" style={leftSectionStyle}>
                      <div className="contentSection">
                        <h3 className="secTitle">Welcome</h3>
                        <p className="subText">Don't have an account?</p>
                        <PrimaryButton
                          type="primary"
                          handleClick={() => handleNavigate()}
                          text="Join Now"
                          className="w-50"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="rightSection">
                      <div className="formWrap">
                        <div className="formTitle">
                          <h3>Sign In</h3>
                        </div>
                        <Form
                          name="loginForm"
                          initialValues={{
                            remember: true,
                          }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Form.Item
                            name="username"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please input your E-mail!",
                              },
                            ]}
                          >
                            <Input
                              name="email"
                              onChange={handleInput}
                              value={loginInput.email}
                              placeholder="Username or Email"
                              className="formControl"
                            />
                          </Form.Item>
                          <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please input your password!",
                              },
                            ]}
                          >
                            <Input.Password
                              name="password"
                              onChange={handleInput}
                              value={loginInput.password}
                              placeholder="Password"
                              className="formControl"
                            />
                          </Form.Item>
                          <Row gutter={16}>
                            <Col xs={12}>
                              <Form.Item
                                name="remember"
                                valuePropName="checked"
                                className="forgotRow"
                              >
                                <Checkbox>Remember me</Checkbox>
                              </Form.Item>
                            </Col>
                            <Col xs={12} className="text-end">
                              <Form.Item>
                                <Link
                                  to={"/forgot-password"}
                                  className="forgotLink"
                                >
                                  Forgot Password?
                                </Link>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadings[0]}
                            className="submitBtn"
                          >
                            Sign In{" "}
                          </Button>
                        </Form>
                        <div className="orSection">
                          <p>or login with</p>
                        </div>
                        <div className="socialLoginBtn">
                          {/* <Link to={'/'} className="sclLogBtn">
                            <FcGoogle />
                          </Link> */}
                          {loginUrl && (
                            <a href={loginUrl} className="sclLogBtn">
                              <FaFacebookF />
                            </a>
                          )}
                          {googleUrl && (
                            <a href={googleUrl} className="sclLogBtn fbIcn">
                              <FcGoogle />
                            </a>
                          )}
                          {/* <Link to={googleUrl} className="sclLogBtn fbIcn">
                            <FaFacebookF />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Login;
