import React, { useEffect, useState } from "react";
import { Row, Col, Card, Spin, Modal, Result, Button, notification } from "antd";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as planService from "../../services/getPlans";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const CheckoutForm = () => {
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [paymentModal, setPaymentModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [getPlan, setGetPlan] = useState([]);
  const [loader, setLoader] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(true);
  const userDetails = useSelector((state) => state?.User?.userDetails);
  const { state } = useLocation();
  const { plan_data } = state;
  let plan_ID = plan_data.plan_id;
  let checkFirstTime = localStorage.getItem("firstTime");

  const getUserPlan = (value) => {
    dispatch(planService.getPlanDetails(value))
      .then((res) => {
        setGetPlan(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hidePaymentModal = () => {
    setPaymentModal(false);
    if (checkFirstTime == 'true') {
      navigate("/create-profile-start/?user_id=" + userDetails?.user_id);
      console.info("1")
    } else {
      console.info("2")
      navigate("/");
    }
  };

  useEffect(() => {
    getUserPlan(plan_ID);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoader(true);
    setErrorMsg("");

    const result = await stripe.createToken(
      elements.getElement(CardNumberElement)
    );

    const paymentMethodObj = {
      type: "card",
      card: elements.getElement(CardNumberElement),
    };
    const paymentMethodResult = await stripe.createPaymentMethod(
      paymentMethodObj
    );

    stripePaymentMethodHandler(
      {
        result: paymentMethodResult,
        plan: getPlan,
      },
      handleResponse
    );
  };

  const stripePaymentMethodHandler = async (data, cb) => {
    let checkFirstTime = localStorage.getItem("firstTime");
    const { result, plan } = data;
    if (result.error) {
      cb(result);
    } else {
      setLoader(true);
      let paymentObj = {
        payment_method_id: result.paymentMethod.id,
        user_id: userDetails?.user_id,
        currency: "USD",
        plan_id: plan[0].id,
        description: "Test payment",
      };
      dispatch(planService.saveArtistPlan(plan_data))
        .then((res) => {
          if (checkFirstTime == 'true') {
            navigate(
              "/create-profile-start/?user_id=" + userDetails?.user_id
            );
            notification.open({
              message: "Plan selected successfully!",
              icon: (
                <CheckCircleOutlined
                  style={{
                    color: "#d62b47",
                  }}
                />
              ),
            });
          } else {
            navigate("/");
            notification.open({
              message: "Plan changed successfully!",
              icon: (
                <CheckCircleOutlined
                  style={{
                    color: "#d62b47",
                  }}
                />
              ),
            });
          }
          // setPaymentModal(true);
          console.info({ res });
        })
        .catch((err) => {
          console.log({ err });
          setLoader(false);
        });
      const paymentResponse = await payment(paymentObj)
        .then((res) => {
          console.log({ res });
        })
        .catch((err) => {
          console.log({ err });
        });
      console.log({ paymentResponse });
      return false;
    }
  };

  const payment = async (data) => {
    dispatch(planService.makePlanPayment(data))
      .then((res) => {
        setPaymentModal(true);
        console.log({ res });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handleResponse = (response) => {
    setLoader(false);
    if (response.error) {
      setErrorMsg(
        typeof response.error === "string"
          ? response.error
          : response.error.message
      );
      return;
    }
    setPaymentCompleted(response.success ? true : false);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        lineHeight: "27px",
        color: "#212529",
        fontSize: "1.1rem",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleBack = () => {
    window.history.back();
  };

  return (
    <div className="paymentWrapper">
      {loading ? (
        <div className="spinDiv">
          <Spin size="large" />
        </div>
      ) : (
    ''
      )}
          <div className="innerWrapper">
          <div className="container">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify="center">
              <Col xs={24} md={18} xl={18}>
                <div className="cardWrapper">
                  <Card
                    className="paymentCard"
                    title={
                      <div className="d-flex justify-content-between w-100">
                        <span>Ready to try?</span>
                        <Button
                          className="submitBtn w-auto"
                          onClick={() => handleBack()}
                        >
                          Back
                        </Button>
                      </div>
                    }
                  >
                    <div className="innerBody">
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                        <Col xs={24} md={12}>
                          <div className="leftSection">
                            <h5 className="cardHeading">
                              Finding Ink {getPlan[0]?.name} Plan
                            </h5>
                            <div className="planDetails">
                              <table className="table" border={0}>
                                <tbody>
                                  <tr>
                                    <td>{getPlan[0]?.name} Plan</td>
                                    <td>$ {getPlan[0]?.price}.00</td>
                                  </tr>
                                  <tr>
                                    <td>Plan Validity</td>
                                    <td>$ {getPlan[0]?.frequency} Month</td>
                                  </tr>
                                  <tr>
                                    <td>Total</td>
                                    <td>$ {getPlan[0]?.price}.00</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Grand Total</strong>
                                    </td>
                                    <td>
                                      <strong>$ {getPlan[0]?.price}.00</strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} md={12}>
                          <div className="my-4">
                            <h6>Add Payment Method</h6>
                            <div className="formWrapper">
                              <form id="payment-form" onSubmit={handleSubmit}>
                                <div className="formGroup">
                                  <label>Card Number</label>
                                  <CardNumberElement
                                    id="cc-number"
                                    options={CARD_ELEMENT_OPTIONS}
                                    className="form-control"
                                  />
                                </div>
                                <Row gutter={16}>
                                  <Col xs={24} md={18}>
                                    <div className="formGroup">
                                      <label>Expiry</label>
                                      <CardExpiryElement
                                        id="expiry"
                                        options={CARD_ELEMENT_OPTIONS}
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Cvv</label>
                                      <CardCvcElement
                                        id="cvv"
                                        options={CARD_ELEMENT_OPTIONS}
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <button
                                  id="submit"
                                  className="submitBtn"
                                  type="submit"
                                  disabled={loader}
                                >
                                  {loader ? "Processing..." : `Confirm & Pay`}
                                </button>
                                {errorMsg && (
                                  <div className="text-danger mt-2">
                                    {errorMsg}
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      <Modal
        open={paymentModal}
        onOk={hidePaymentModal}
        onCancel={hidePaymentModal}
        footer={false}
        className="welcomeModal"
      >
        <div className="modalBody">
          <Result
            icon={<CheckCircleOutlined />}
            title={"Payment Successful"}
            subTitle="Let's explore your tattoo imaginaton and connect you with artists."
            extra={
              <Button type="primary" onClick={hidePaymentModal}>
                Explore Finding Ink
              </Button>
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default CheckoutForm;
