import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Upload,
  Input,
  message,
  Select,
  Checkbox,
  notification,
  Button,
  Carousel,
  Image,
  Divider,
  Modal,
  Spin,
  Space,
  Alert,
} from "antd";
import Backdrop from "@mui/material/Backdrop";
import {
  CheckCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { IoSearch } from "react-icons/io5";
import { useNavigate, Link, useLocation } from "react-router-dom";
import * as profileService from "../../../services/createProfile";
import * as profileEditService from "../../../services/editProfile";
// import * as mediaDeleteData from "../../../services/mediaDeleteService";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { TATTOO_URL } from "../../../Helper";
import { CardCover } from "../../../constant/images";
import { Country, State, City } from "country-state-city";
import * as categoryService from "../../../services/categoryService";
import * as certificateService from "../../../services/certificateService";
import Tab from "react-bootstrap/Tab";
import Swal from "sweetalert2";
import Tabs from "react-bootstrap/Tabs";
import * as AuthActions from "../../../store/actions/user";
import {clearVideoDetails} from "../../../store/actions/user";
import Title from "antd/es/skeleton/Title";
// import Backdrop from "@mui/material/Backdrop";

const EditProfileArtist = () => {
  const Option = Select.Option;
  const [albumForm] = Form.useForm();
  const [businessForm] = Form.useForm();
  const [sliderForm] = Form.useForm();
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  const [profileUrl, setProfileUrl] = useState();
  const [tags, setTags] = useState([]);
  const [artistData, setArtistData] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const userID = queryParams.get("user_id");
  const [loadings, setLoadings] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [currency, setCurrency] = useState("");
  const [category, setCategory] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imageUrlSlider, setImageUrlSlider] = useState();
  const [fileList, setFileList] = useState([]);
  const [slider, setSlider] = useState([]);
  const [fileListNew, setFileListNew] = useState([]);
  // const [albums, setAlbums] = useState([]);
  const [tagsAlbum, setTagsAlbum] = useState([]);
  const [formAlbums, setFormAlbums] = useState([]);
  const [formUpdateDetails, setFormUpdateDetails] = useState([]);
  const [updateAlbums, setUpdateAlbums] = useState([]);
  // const [repeaterData, setRepeaterData] = useState([]);
  const [profileInput, setProfileInput] = useState("");
  const [profileInitValue, setProfileInitValue] = useState("");
  const [servicIds, setServiceIds] = useState([]);
  const reduxVideoDetails = useSelector((state) => state?.User?.videoDetails);
  const [open, setOpen] = useState(false);
  const [deleteImageID, setDeleteImageID] = useState([]);
  const [sliderDeleteImageID, setSliderDeleteImageID] = useState([]);
  const userDetails = useSelector((state) => state?.User?.userDetails);
  const [hideVideo, setHideVideo] = useState(false);
  const [imageLimit, setImageLimit] = useState(0);
  const [newFile, setNewFile] =useState();


  const onChangeCheckbox = (checkedValues) => {
    // console.log("dfgdfg",checkedValues);
    // const pInitValue = [ ...profileInitValue ];
    // console.log("pInitValue",pInitValue);
    // pInitValue.tattoo_service_ids = checkedValues;
    setServiceIds(checkedValues);
  };

  // console.info("yrtyrtyrt",servicIds);
  // console.info(
  //   "unused_variable",
  //   profileUrl,
  //   tagsAlbum,
  //   formUpdateDetails,
  //   imageUrl,
  //   setFileListNew,
  //   setLoadings
  // );

  // const enterLoading = (index) => {
  //   setLoadings((prevLoadings) => {
  //     const newLoadings = [...prevLoadings];
  //     newLoadings[index] = true;
  //     return newLoadings;
  //   });
  //   setTimeout(() => {
  //     setLoadings((prevLoadings) => {
  //       const newLoadings = [...prevLoadings];
  //       newLoadings[index] = false;
  //       return newLoadings;
  //     });
  //   }, 6000);
  // };
  // const new_imageFile = newFile?.map(item=>item.media_url);
  // console.log(new_imageFile,"new_imageFile")

  useEffect(() => {
    const fetchCountries = () => {
      let countries = Country.getAllCountries();
      setCountries(countries);
      setCurrency(currency);
    };
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let key = Math.random().toString(36).slice(2, 7);

  // console.info(artistData?.albums);
  useEffect(() => {
    const fetchState = () => {
      let states = State.getStatesOfCountry(countryId);
      setStates(states);
    };

    fetchState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  useEffect(() => {
    const fetchCity = () => {
      let city = City.getCitiesOfState(countryId, stateId);
      setCity(city);
    };
    fetchCity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  useEffect(() => {
    const fetchAllCities = () => {
      let cities = City.getAllCities();
      setCities(cities);
    };
    fetchAllCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const fatchCategories = () => {
    dispatch(categoryService.getCategories())
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  const handleInput = (input, key) => (e) => {
    if (input === "country") {
      setCountryId(e);
      let i = Country.getCountryByCode(e);
      setProfileInput({ ...profileInput, [input]: i.name });
    } else if (input === "state") {
      let i = State.getStateByCodeAndCountry(e, countryId);
      setStateId(e);
      setProfileInput({ ...profileInput, [input]: i.name });
    } else if (input === "city") {
      setProfileInput({ ...profileInput, [input]: e });
    } else if (input === "select_area_ids") {
      setProfileInput({ ...profileInput, [input]: e });
    } else if (
      input === "currency" ||
      input === "category_name" ||
      input === "tattoo_service_ids" ||
      input === "certificates"
    ) {
      setProfileInput({ ...profileInput, [input]: e });
    } else {
      setProfileInput({ ...profileInput, [input]: e.target.value });
    }
  };

  const fatchCertificates = () => {
    dispatch(certificateService.getCertificates())
      .then((res) => {
        setCertifications(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  useEffect(() => {
    fatchCategories();
    fatchCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTags = () => {
    setOpen(true);
    setLoading(true);
    dispatch(profileService.getTags())
      .then((res) => {
        setTags(res.data);
        setOpen(false);
        setLoading(true);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const fetchTagsAlbum = () => {
    dispatch(profileService.getTags())
      .then((res) => {
        setTagsAlbum(res.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const handleCancel = () => setPreviewOpen(false);

  const fetchArtistData = (user_id) => {
    dispatch(profileService.getArtistData(user_id))
      .then((res) => {
        let userProfile = res.data.user_profile;
        if (servicIds.length == 0) {
          setServiceIds(JSON.parse(userProfile.tattoo_service_ids));
        }
        setNewFile(res?.data?.user_profile?.slider_media_id);
        setLoading(true);
        let certificatesData = [];
        if (userProfile.certificate) {
          userProfile.certificate.forEach((certificate) => {
            certificatesData.push(certificate.id);
          });
        }

        let countries_data = Country.getAllCountries();
        let country = countries_data.find(
          (country) => country.name === userProfile.country
        );
        let states = [];
        if (country) {
          states = State.getStatesOfCountry(country.isoCode);

          setStates(states);
          let state = states.find((state) => state.name === userProfile.state);
          if (state) {
            let cities = City.getCitiesOfState(country.isoCode, state.isoCode);
            setCity(cities);
          }
        }
        userProfile["certificate"] = certificatesData;
        userProfile["tattoo_service_ids"] = JSON.parse(
          userProfile.tattoo_service_ids
        );
        setProfileInitValue(userProfile);
        setArtistData(res.data);
        setProfileInput(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };
  const location = useLocation();
  useEffect(() => {
    fetchTags();
    fetchTagsAlbum();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchArtistData(userDetails?.user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const beforeUpload = (file) => {
    // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    // const isLt2M = file.type === 'image/jpeg' || file.type === 'image/png'
    // ? file.size / 1024 / 1024 < 2
    // : true;

    // const videoFile = file.type === "video/mp4";

    // if (!isJpgOrPng && !videoFile) {
    //   message.error("You can only upload JPG/PNG file!");
    // }

    // // const isLt2M = file.size / 1024 / 1024 < 2;

    // if (isJpgOrPng && !isLt2M) {
    //   message.error('Image must be smaller than 2MB!');
    // }

    // if(imageLimit >= 10){
    //   message.error("you can upload more than 10 images!");
    // }

    // return isJpgOrPng || file.type === 'video/mp4';
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
  
    // if (isImage && file.size / 1024 / 1024 > 2) {
    //   message.error("Image must be smaller than 2MB!");
    //   return false;
    // }

    if(imageLimit >= 10){
      message.error("you can't upload more than 10 images!");
    }
  
    return true;
  };

  const handleImageChange = (info, index) => {
    console.log(info,"infooooo")
    if (info.file.status === "done") {
      const newImage = artistData?.albums?.map(item=> item?.mediaupload);
      const imageSet = newImage?.length;
      message.success(`${info.file.name} uploaded successfully.`)
      const albumsData = { ...updateAlbums };
      if (albumsData[index] && Array.isArray(albumsData[index])) {
        albumsData[index].push(info.file.response);
      } else {
        albumsData[index] = [info.file.response];
      }
      setUpdateAlbums(albumsData);
      setImageLimit(albumsData[index].length+imageSet)
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const uploadProfile = (
    <div>
      <div
        style={{
          marginTop: 8,
        }}
      >
        FI
      </div>
    </div>
  );

  const uploadImage = async (options, index) => {
    let plan_id = artistData?.user_profile?.plan_id;
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    };
    let file_type = file.type;
    // Free
    // if (file_type == "video/mp4" && plan_id == 5) {
    //   alert("Video Upload is not allowed in free plan");
    //   return false;
    // }
    //Pro
    if (file_type == "video/mp4" && (plan_id == 3 || plan_id == 4 || plan_id == 5)) {
      let uploadedVideos = { ...reduxVideoDetails };
      console.log(uploadedVideos,"uploadedVideos");
      console.log("yha aa rha hai");
      if (uploadedVideos[index]) {
        alert(
          "One video is alredy uploded for the album and only one video per album is allowed"
        );
        return false;
      }
    }
    fmData.append("file", file);

    try {
      console.log("newDatata")
      console.log(fmData,config,"configconfig")
      const res = await axios
        .post(TATTOO_URL + "api/media_uploads", fmData, config)
        .then((res) => {
          console.log(res,"video Response");
          let uploadedVideos = { ...reduxVideoDetails };
          uploadedVideos[index] = res.data.data.id;
          dispatch(AuthActions.video_upload(uploadedVideos));
          onSuccess(res?.data?.data?.id);
          setImageUrl(res);
          profileInput.profile_photo_id = res?.data?.data?.id;
        });
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
      console.warn(error);
    }
  };
  const uploadProfileImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    };
    fmData.append("file", file);

    try {
      const res = await axios.post(
        TATTOO_URL + "api/media_uploads",
        fmData,
        config
      );
      onSuccess(res?.data?.data?.id);
      setProfileUrl(res?.data?.data?.media_url);
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
      console.warn(error);
    }
  };

  useEffect(() => {
window.scrollTo(0,0);
  },[]);

  useEffect(() => {
    setImageUrl(profileInput.profile_photo_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const businessFormSubmit = (businessValues) => {
    businessValues.user_id = userID;
    businessValues.tattoo_service_ids = servicIds;
    businessValues.profile_pic_url = profileUrl;
    dispatch(profileService.updateProfile(businessValues))
      //  dispatch(profileEditService.editProfile(formUpdateData))
      .then((res) => {
        setFormUpdateDetails(res.data);

        notification.open({
          message: "Profile updated!",
          icon: (
            <CheckCircleOutlined
              style={{
                color: "#d62b47",
              }}
            />
          ),
        });
        window.scrollTo(0,0);
        //navigate("/user-profile/?user_id=" + userID);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const removeImageSlider = (values) => {
    console.info(values?.id);
    const ImageId = [...sliderDeleteImageID];
    ImageId.push(values?.id);
    // console.info({ImageId})
    setSliderDeleteImageID(ImageId);
  };

  // const handleNavigate = () => {
  //   navigate("/edit-profile/?user_id=" + userID);
  // };

  // New code -------------------------

  const fetchAlbums = () => {
    dispatch(profileService.getAlbums(userID))
      .then((res) => {
        setFormAlbums(res.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  // const deleteAlbum = (index) => {
  //   const list = [...repeaterData];
  //   list.splice(index,1);
  //   setRepeaterData(list);
  // }

  useEffect(() => {
    fetchAlbums();
  }, []);

  useEffect(() => {
    albumForm.setFieldsValue(formAlbums);
  }, [formAlbums, albumForm]);

  const formFinish = (values) => {
    let formData = {};
    formData.albums = values.albums;
    formData.user_id = userID;
    formData.isUpdate = true;
    formData.album_image_ids = updateAlbums;
    formData.album_delete_image_ids = deleteImageID;
    formData.slider_media_id = slider;
    formData.slider_delete_image_ids = sliderDeleteImageID;
    formData.albums.forEach((value, index) => {
      delete value["albumImages"];
    });
    dispatch(profileEditService.editProfile(formData))
      .then((res) => {
        notification.open({
          message: "Profile updated!",
          icon: (
            <CheckCircleOutlined
              style={{
                color: "#d62b47",
              }}
            />
          ),
        });
        navigate("/user-profile/?user_id=" + userID);
        //return;
        // navigate("/user-profile/?user_id=" + userID);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const onFinishUpdate = async () => {
    try {
      await sliderForm.validateFields([
        "albumImages",
        "album_tag_ids",
        "album_tag_ids",
      ]);

      const data = {
        artist_name: artistData?.user_profile?.artist_name,
        category_name: artistData?.user_profile?.category_name,
        ph_ext: artistData?.user_profile?.ph_ext,
        phone_number: artistData?.user_profile?.phone_number,
        country: artistData?.user_profile?.country,
        first_name: artistData?.user_profile?.first_name,
        last_name: artistData?.user_profile?.last_name,
        user_id: userDetails.user_id,
        //slider_media_id: slider,
      };
      dispatch(profileService.updateProfile(data))
        .then((res) => {
          //return;
          navigate("/user-profile/?user_id=" + userDetails.user_id);
        })
        .catch((err) => {
          console.info("Get Error Edit State...");
        });
    } catch {
      console.log("Validation error");
    }
  };

  const uploadSlider = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const previewUpload = (file) => {
    const isVideo = file.type.startsWith('video/');
  
    if (isVideo) {
      message.error('Video files are not allowed in profile Slide!');
      return false; 
    }
  
    return true; 
  };

  const [upload, setUpload] = useState([]);

  const handleChangeImageSlider = (info) => {
    setFileList(info.fileList);
    if (info.file.status === 'done') {
        setSlider([...slider, info.file.response]);
    }
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadImageSlider = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.post(
        TATTOO_URL + "api/media_uploads",
        fmData,
        config
      );
      onSuccess(res.data.data.id);
      setImageUrlSlider(res);
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
      console.warn(error);
    }
  };

  const handleRemoveImage = (index, id) => {
    const updatedSliderDeleteImageID = [...sliderDeleteImageID];
    updatedSliderDeleteImageID.push(id);
    const updatedSliderMedia = artistData.user_profile.slider_media_id.filter(media => media.id !== id);

    setArtistData((prevData) => ({
      ...prevData,
      user_profile: {
        ...prevData.user_profile,
        slider_media_id: updatedSliderMedia,
      },
    }));
  
    setSliderDeleteImageID(updatedSliderDeleteImageID);
  };

  const deleteImage = (imageInfo, index) => {
    const ImageIds = [...deleteImageID];
    ImageIds.push(imageInfo?.uuid);
    // }
    setDeleteImageID(ImageIds);
    dispatch(clearVideoDetails());
    setImageLimit(imageLimit-1)
  };

  const removeVideo = (id) => {
    setHideVideo(true);
    console.info({ id });
    const ImageIds = [...deleteImageID];
    ImageIds.push(id);
    setDeleteImageID(ImageIds);
  };

  let Key = Math.floor(Math.random() * 10000000);

  return (
    <div className="editArtistWrapper">
      {loading ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <Spin size="large" />
        </Backdrop>
      ) : (
        ""
      )}
      <div className="innerWrap">
        <div className="container">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify="center">
            <Col xs={24} md={24}>
              <Card bordered={false} className="profileCard">
                <div className="cardBody">
                  <div className="sliderWrapper">
                    <div className="slideWrap">
                      {artistData?.user_profile?.slider_media_id?.length > 0 ? (
                        <Carousel autoplay>
                          {artistData?.user_profile?.slider_media_id?.map(
                            (slide, index) => {
                              return (
                                <div className="slides" key={index}>
                                  <Image
                                    preview={false}
                                    src={slide?.url ?? slide?.url}
                                    alt="slides"
                                    fluid
                                  />
                                </div>
                              );
                            }
                          )}
                        </Carousel>
                      ) : (
                        <Image
                          preview={false}
                          src={CardCover}
                          alt="slides"
                          fluid
                        />
                      )}
                    </div>
                  </div>
                  <div className="tabsWrapper">
                    <Tabs
                      defaultActiveKey="info"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="info" title="Profile information">
                        <Form
                          name="editArtistProfile"
                          onFinish={businessFormSubmit}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          layout="vertical"
                          form={businessForm}
                          initialValues={profileInitValue}
                        >
                          <div className="coverWrapper">
                            {/* <div className='editButton'>
                                    <IconButton
                                        type="primary"
                                        handleClick={() => handleNavigate()}
                                        className='editBtn'
                                        icon={<FaEdit />}
                                    />
                                </div> */}
                            <div className="infoSection mb-0">
                              <div className="information">
                                <div className="imgSection">
                                  <Form.Item
                                    name="profile_pic_url"
                                    getValueFromEvent={normFile}
                                    className="customDragger"
                                  >
                                    <Upload
                                      name="profile_pic_url"
                                      listType="picture-card"
                                      className="avatar-uploader"
                                      multiple={false}
                                      showUploadList={false}
                                      customRequest={uploadProfileImage}
                                      beforeUpload={beforeUpload}
                                      maxCount={1}
                                    >
                                      {profileUrl ? (
                                        <img
                                          src={profileUrl}
                                          alt="avatar"
                                          style={{
                                            width: "100%",
                                          }}
                                        />
                                      ) : artistData?.user_profile
                                          ?.profile_pic_url ? (
                                        <img
                                          src={
                                            artistData?.user_profile
                                              ?.profile_pic_url
                                          }
                                          alt="avatar"
                                          style={{
                                            width: "100%",
                                          }}
                                        />
                                      ) : (
                                        uploadProfile
                                      )}
                                    </Upload>
                                  </Form.Item>
                                </div>
                                <div className="nameSection noFlex">
                                  <h3 className="name">
                                    {/* {artistData?.user_profile?.artist_name} */}
                                  </h3>
                                  <p>
                                    {artistData?.user_profile?.category_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Divider orientation="left" className="dividerTitle">
                            Account Information
                          </Divider>
                          <div className="px-3 relative">
                            <div className="upgradeSection">
                              <Link
                                className="planLink"
                                to={
                                  "/pricing/?upgrade_plan=" +
                                  userDetails?.user_id
                                }
                              >
                                Upgrade Plan
                              </Link>
                            </div>
                            <Row gutter={16}>
                              <Col xs={24} md={8}>
                                <Form.Item name="user_name" label="Name">
                                  <Input
                                    disabled
                                    key={Key}
                                    defaultValue={
                                      artistData?.user_profile?.first_name +
                                      " " +
                                      artistData?.user_profile?.last_name
                                    }
                                    className="formControl"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={8}>
                                <Form.Item name="userEmail" label="Email">
                                  <Input
                                    disabled
                                    defaultValue={artistData?.email}
                                    key={Key}
                                    // initialValues={formUpdateData?.email}
                                    className="formControl"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <Divider orientation="left" className="dividerTitle">
                            Contact Information
                          </Divider>
                          <div className="px-3">
                            <Row gutter={16}>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  name="address_line1"
                                  label="Address Line 1"
                                >
                                  <Input
                                    placeholder="Address Line 1"
                                    key={Key}
                                    defaultValue={
                                      artistData?.user_profile?.address_line1
                                    }
                                    // initialValues={formUpdateData?.address_line1}
                                    className="formControl"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  name="address_line2"
                                  label="Address Line 2"
                                >
                                  <Input
                                    placeholder="Address Line 2"
                                    key={Key}
                                    defaultValue={
                                      artistData?.user_profile?.address_line2
                                    }
                                    className="formControl"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={8}>
                                <Form.Item name="country" label="Country">
                                  <Select
                                    showSearch
                                    placeholder="Select Country"
                                    className="formControl"
                                    onChange={handleInput("country")}
                                    key={Key}
                                    value={profileInput?.country}
                                    name="country"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                      optionA.children
                                        .toLowerCase()
                                        .localeCompare(
                                          optionB.children.toLowerCase()
                                        )
                                    }
                                    defaultValue={
                                      artistData?.user_profile?.business_country
                                    }
                                  >
                                    {countries?.map((country) => {
                                      return (
                                        <Option
                                          value={country.isoCode}
                                          key={country.isoCode}
                                        >
                                          {country.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={8}>
                                <Form.Item
                                  name="state"
                                  label="State"
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <Select
                                    showSearch
                                    name="state"
                                    placeholder="Select State"
                                    key={Key}
                                    className="formControl"
                                    onChange={handleInput("state")}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                      optionA.children
                                        .toLowerCase()
                                        .localeCompare(
                                          optionB.children.toLowerCase()
                                        )
                                    }
                                    defaultValue={
                                      artistData?.user_profile?.state
                                    }
                                  >
                                    {states?.map((state, index) => {
                                      return (
                                        <Option
                                          value={state.isoCode}
                                          key={index}
                                        >
                                          {state.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={8}>
                                <Form.Item name="city" label="City">
                                  <Select
                                    showSearch
                                    name="city"
                                    placeholder="Select city"
                                    key={Key}
                                    className="formControl"
                                    onChange={handleInput("city")}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                      optionA.children
                                        .toLowerCase()
                                        .localeCompare(
                                          optionB.children.toLowerCase()
                                        )
                                    }
                                    defaultValue={
                                      artistData?.user_profile?.city
                                    }
                                  >
                                    {city?.map((city, index) => {
                                      return (
                                        <Option value={city.name} key={index}>
                                          {city.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={8}>
                                <Form.Item name="zip_code" label="Zip Code">
                                  <Input
                                    placeholder="Zip Code"
                                    key={Key}
                                    defaultValue={
                                      artistData?.user_profile?.zip_code
                                    }
                                    onChange={profileInput?.zip_code}
                                    className="formControl"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={8}>
                                <Form.Item
                                  label="Phone Number"
                                  className="mb-0"
                                >
                                  <Input.Group compact className="dFlexBet">
                                    <Form.Item
                                      name="ph_ext"
                                      className="w-30"
                                      rules={[
                                        {
                                          // required: true,
                                          message: "Code required",
                                          pattern: new RegExp("^[0-9, +]*$"),
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="+1"
                                        key={Key}
                                        name="ph_ext"
                                        value={profileInput?.ph_ext}
                                        className="w-100 frmCntrl"
                                        defaultValue={
                                          artistData?.user_profile?.ph_ext
                                        }
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      name="phone_number"
                                      className="w-70"
                                      rules={[
                                        {
                                          pattern: /^[\d]{0,15}$/,
                                          message: "Enter valid phone number.",
                                        },
                                        {
                                          maxLength: 15,
                                          message: "Enter valid phone number.",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Phone Number"
                                        className="w-100 fmCntrl"
                                        key={Key}
                                        name="phone_number"
                                        value={profileInput?.phone_number}
                                        defaultValue={
                                          artistData?.user_profile?.phone_number
                                        }
                                      />
                                    </Form.Item>
                                  </Input.Group>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <Divider orientation="left" className="dividerTitle">
                            Business Information
                          </Divider>
                          <div className="px-3">
                            <Row gutter={16}>
                              <Col xs={24} md={8}>
                                <Form.Item
                                  name="artist_name"
                                  label="Artist/Studio Name"
                                >
                                  <Input
                                    placeholder="Artist/Studio Name"
                                    key={Key}
                                    defaultValue={
                                      artistData?.user_profile?.artist_name
                                    }
                                    className="formControl"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={8}>
                                <Form.Item
                                  name="category_name"
                                  label="Category"
                                >
                                  <Select
                                    placeholder="Select Category"
                                    key={Key}
                                    className="formControl"
                                    defaultValue={
                                      artistData?.user_profile?.category_name
                                    }
                                    name="category_name"
                                    value={profileInput?.category_name}
                                  >
                                    {category?.map((item, index) => {
                                      return (
                                        <Option value={item} key={index}>
                                          {item}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={8}>
                                <Form.Item
                                  name="business_website_address"
                                  label="Website Link"
                                >
                                  <Input
                                    placeholder="Website Link"
                                    key={Key}
                                    defaultValue={
                                      artistData?.user_profile
                                        ?.business_website_address
                                    }
                                    className="formControl"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={8}>
                                <Form.Item name="first_name" label="First Name">
                                  <Input
                                    placeholder="First Name"
                                    key={Key}
                                    defaultValue={
                                      artistData?.user_profile?.first_name
                                    }
                                    className="formControl"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={8}>
                                <Form.Item name="last_name" label="Last Name">
                                  <Input
                                    placeholder="Last Name"
                                    key={Key}
                                    defaultValue={
                                      artistData?.user_profile?.last_name
                                    }
                                    className="formControl"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24}>
                                <Form.Item
                                  name="additional_details"
                                  label="Business Description"
                                >
                                  <Input.TextArea
                                    placeholder="Business Description"
                                    key={Key}
                                    defaultValue={
                                      artistData?.user_profile
                                        ?.additional_details
                                    }
                                    className="formControl"
                                    rows={5}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24}>
                                <Form.Item
                                  name="certificate"
                                  label="Certification and Awards"
                                >
                                  <Select
                                    mode="multiple"
                                    key={Key}
                                    allowClear
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                    }}
                                    name="certificate"
                                    placeholder="Select certificates"
                                    className="formControl"
                                 defaultValue={artistData?.user_profile?.certificate}
                                  >
                                    {certifications &&
                                      certifications?.map(
                                        (certificate, index) => {
                                          return (
                                            <>
                                              <Option
                                                value={certificate?.id}
                                                key={index}
                                              >
                                                {certificate?.certificate_name}
                                              </Option>
                                            </>
                                          );
                                        }
                                      )}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24}>
                                <Form.Item
                                  name="select_area_ids"
                                  label="Cities Provided"
                                >
                                  <Select
                                    mode="multiple"
                                    key={Key}
                                    allowClear
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                    }}
                                    name="select_area_ids"
                                    placeholder="Please select cities"
                                    value={profileInput?.select_area_ids}
                                    defaultValue={
                                      artistData?.user_profile?.select_area_ids
                                    }
                                  >
                                    {cities?.map((city, index) => {
                                      return (
                                        <Option value={city.name} key={index}>
                                          {city.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24}>
                                <Form.Item
                                  name="jobCost"
                                  label="Typical Hourly Rate"
                                >
                                  <Input.Group size="large">
                                    <Row gutter={8}>
                                      <Col xs={4} sm={8}>
                                        <Form.Item
                                          name="currency"
                                          className="ty-hour-rate"
                                        >
                                          <Select
                                            placeholder="Select"
                                            key={Key}
                                            showSearch
                                            name="currency"
                                            className="formControl"
                                            defaultValue={
                                              artistData?.user_profile?.currency
                                            }
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              option.children
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                            }
                                            filterSort={(optionA, optionB) =>
                                              optionA.children
                                                .toLowerCase()
                                                .localeCompare(
                                                  optionB.children.toLowerCase()
                                                )
                                            }
                                          >
                                            {countries?.map((country) => {
                                              return (
                                                <Option
                                                  value={country.currency}
                                                  key={country.isoCode}
                                                >
                                                  {country.currency}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={12}>
                                        <Input.Group compact>
                                          <Form.Item name="from_price">
                                            <Input
                                              name="from_price"
                                              key={Key}
                                              placeholder="From"
                                              className="frmCntrl"
                                              defaultValue={
                                                artistData?.user_profile
                                                  ?.from_price
                                              }
                                            />
                                          </Form.Item>
                                          <Form.Item name="to_price">
                                            <Input
                                              name="to_price"
                                              key={Key}
                                              placeholder="To"
                                              className="fmCntrl"
                                              defaultValue={
                                                artistData?.user_profile
                                                  ?.to_price
                                              }
                                            />
                                          </Form.Item>
                                        </Input.Group>
                                      </Col>
                                    </Row>
                                  </Input.Group>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24}>
                                <Form.Item
                                  name="tattoo_service_ids"
                                  label="Type of tattoos"
                                >
                                  <Input
                                    className="formControl mb-4 displayHide"
                                    key={Key}
                                    placeholder="Search for services"
                                    prefix={<IoSearch />}
                                  />
                                  <Checkbox.Group
                                    name="tattoo_service_ids"
                                    key={Key}
                                    options={tags}
                                    onChange={onChangeCheckbox}
                                    defaultValue={
                                      servicIds ? servicIds : profileInitValue.tattoo_service_ids
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24}>
                                <Form.Item className="text-end">
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loadings[0]}
                                    className="submitBtn w-auto"
                                  >
                                    Update
                                  </Button>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      </Tab>
                      <Tab eventKey="slider" title="Profile Slider">
                        <Card bordered={false} className="profileCard">
                          <div className="cardBody">
                            <Form
                              form={sliderForm}
                              name="editProfileForm"
                              onFinish={onFinishUpdate}
                              onFinishFailed={onFinishFailed}
                              autoComplete="off"
                              layout="vertical"
                            >
                              <Divider
                                orientation="left"
                                className="dividerTitle"
                              >
                                Profile Slideshow
                              </Divider>
                              <div className="px-3">
                              <div className="fileList">
                              {artistData?.user_profile?.slider_media_id?.map(
                               (slide, index) => {
                                    return (
                                      <div className="preImg" key={index}>
                                        <Image
                                          preview={false}
                                          src={slide?.url ?? slide?.url}
                                          alt="slides"
                                          fluid
                                        />
                                        <Button onClick={() => handleRemoveImage(index, slide.id)} className="dltBtn"><DeleteOutlined /></Button> 
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                                <Form.Item
                                  className="flex-wrap pt-3"
                                  name="slider_media_id"
                                  valuePropName="fileList"
                                  getValueFromEvent={(info) =>
                                    info.fileList
                                  }
                                >
                                  <Upload
                                    name= 'file'
                                    listType="picture-card"
                                    // onPreview={handlePreview}
                                    onChange={handleChangeImageSlider}
                                    beforeUpload={previewUpload}
                                    // key={key}
                                    multiple={true}
                                    onRemove={(file) => handleRemoveImage(file.id)}
                                    customRequest={uploadImageSlider}
                                    defaultFileList={
                                      artistData?.user_profile?.slider_media_id || []
                                    }
                                    showUploadList={{
                                      showPreviewIcon: false,
                                      showRemoveIcon: true,
                                    }}
                                  >
                                    {fileList.length >= 10
                                      ? null
                                      : uploadSlider}
                                  </Upload>
                                  {/* {artistData?.user_profile?.slider_media_id?.length === 0 && fileList.length === 0 && */}
                                <p style={{color:"red", fontSize: "0.7rem",}}>*Optical Resolution: 650 * 450</p>
                                {/* } */}
                                </Form.Item>
                                <Divider
                                  orientation="left"
                                  className="dividerTitle"
                                >
                                  Album
                                </Divider>
                                <Alert
                                  message="Showcase your portfolio with highlight photos."
                                  type="info"
                                  showIcon
                                  closable
                                  className="mb-5"
                                />
                              </div>
                            </Form>
                            <Form
                              form={albumForm}
                              onFinish={formFinish}
                              initialValues={profileInitValue}
                              key={key}
                              layout="vertical"
                            >
                              <div className="px-5">
                                <Form.List
                                  name="albums"
                                  className="flex-wrap pt-3"
                                >
                                  
                                  {(fields, { add, remove }) => (
                                    artistData?.user_profile?.plan_id === 5 ? (
                                      fields.length >= 5 ? add = undefined : ''
                                  ) :artistData?.user_profile?.plan_id === 3 ? (
                                      fields.length >= 10 ? add = undefined : ''
                                  ) : artistData?.user_profile?.plan_id === 1 ? (
                                       fields.length >= 50 ? add = undefined : '')
                                       : (''),
                                    <>
                                      {fields.map((field, index) => (
                                        <Space
                                          key={index}
                                          className="d-flex w-100 repeater"
                                          size={30}
                                        >
                                          <Row gutter={16}>
                                            <Col xs={24} md={8}>
                                              <div className="w-100">
                                                <Form.Item
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "album_name",
                                                  ]}
                                                  label="Album Name"
                                                  className="w-100"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Please enter a name",
                                                    },
                                                  ]}
                                                >
                                                  <Input placeholder="Name" />
                                                </Form.Item>

                                                <Form.Item
                                                  name={[
                                                    field.name,
                                                    "album_tag_ids",
                                                  ]}
                                                  label="Album Tags"
                                                  className="w-100 "
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Please add album tag",
                                                    },
                                                  ]}
                                                >
                                                  <Select
                                                    placeholder="What type of tattoos do you provide?"
                                                    className="formControl h-auto"
                                                    mode="multiple"
                                                    allowClear
                                                  >
                                                    {tags.map((tag) => {
                                                      return (
                                                        <Option
                                                          value={tag}
                                                          id={tag}
                                                        >
                                                          {tag}
                                                        </Option>
                                                      );
                                                    })}
                                                  </Select>
                                                </Form.Item>
                                              </div>
                                            </Col>
                                            <Col xs={24} md={16}>
                                              <div className="w-100 d-flex align-items-start">
                                                <div
                                                  className="videoWrapper"
                                                  style={{ width: "40%" }}
                                                  key={index}
                                                >
                                                  {formAlbums &&
                                                    formAlbums?.albums &&
                                                    formAlbums?.albums.length >
                                                      0 &&
                                                    formAlbums?.albums?.map(
                                                      (album, i) =>
                                                        i === index &&
                                                        album &&
                                                        album?.albumVideos?.map(
                                                          (video) => (
                                                            <div
                                                              className={
                                                                hideVideo ===
                                                                false
                                                                  ? "videoSection"
                                                                  : "hideVideo"
                                                              }
                                                              key={index}
                                                            >
                                                              {console.info(
                                                                "asdsda",
                                                                i
                                                              )}
                                                              {console.info(
                                                                "aretertertsdsda",
                                                                index
                                                              )}
                                                              <video
                                                                controls
                                                                width="150"
                                                                key={i}
                                                              >
                                                                <source
                                                                  src={
                                                                    video?.url
                                                                  }
                                                                  type="video/mp4"
                                                                />
                                                              </video>
                                                              <div className="buttonWrapper">
                                                                <button
                                                                  onClick={() =>
                                                                    removeVideo(
                                                                      video?.uuid
                                                                    )
                                                                  }
                                                                >
                                                                  Remove{" "}
                                                                  <DeleteOutlined />
                                                                </button>
                                                              </div>
                                                            </div>
                                                          )
                                                        )
                                                    )}
                                                </div>
                                                <div style={{ width: "50%" }}>
                                                  <Form.Item
                                                    {...field}
                                                    name={[
                                                      field.name,
                                                      "albumImages",
                                                    ]}
                                                    valuePropName="fileList"
                                                    getValueFromEvent={(info) =>
                                                      info.fileList
                                                    }
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Please upload album images",
                                                      },
                                                    ]}
                                                  >
                                                      <Upload
                                                        name="image"
                                                        listType="picture-card"
                                                        onPreview={handlePreview}
                                                        //onRemove = {removeImage}
                                                        showUploadList={{
                                                          showPreviewIcon: true,
                                                          showRemoveIcon: true,
                                                        }}
                                                        // fileList={fileUpload}
                                                        onRemove={(info) =>
                                                          deleteImage(info, index)
                                                        }
                                                        accept="image/png, image/jpeg, video/mp4"
                                                        onChange={(info) =>
                                                          handleImageChange(
                                                            info,
                                                            index
                                                          )
                                                        }
                                                        maxCount={10}
                                                        // beforeUpload={beforeUpload}
                                                        customRequest={(op) =>
                                                          uploadImage(op, index)
                                                        }
                                                      >
                                                      {<PlusOutlined />}
                                                    </Upload>
                                                  </Form.Item>
                                                  <p style={{color:"red", fontSize: "0.7rem",}}>*Optical Resolution:650*450</p>
                                                </div>

                                                {/* <Form.Item
                                                    {...field}
                                                    name={[
                                                      field.name,
                                                      "albumVideos",
                                                    ]}
                                                    valuePropName="fileList"
                                                    getValueFromEvent={(info) =>
                                                      info.fileList
                                                    }
                                                  ></Form.Item> */}

                                                <div
                                                  style={{ with: "10%" }}
                                                  className="removeSection"
                                                >
                                                  <MinusCircleOutlined
                                                    className="submitBtn w-auto h-auto"
                                                    onClick={() =>
                                                      remove(field.name)
                                                    }
                                                    // onClick={() => deleteAlbum(index)}
                                                  />{" "}
                                                  <Form.Item
                                                    {...field}
                                                    name={[
                                                      field.name,
                                                      "album_id",
                                                    ]}
                                                  >
                                                    <Input type="hidden" />
                                                  </Form.Item>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Space>
                                      ))}
                                      <Form.Item>
                                        <Button
                                          className="submitBtn w-auto"
                                          type="dashed"
                                          onClick={()=>{
                                            if (artistData?.user_profile?.plan_id === 5 && fields.length >= 5) {
                                              message.error("You cannot add more than 5 albums.");
                                              return;
                                            }
                                            add();
                                          }
                                          }
                                          block
                                          icon={<PlusOutlined />}
                                        >
                                          Add field
                                        </Button>
                                      </Form.Item>
                                    </>
                                  )}
                                </Form.List>
                                <Form.Item>
                                  <div className="updateBtnWrapper">
                                    <Button type="primary" htmlType="submit">
                                      Update
                                    </Button>
                                  </div>
                                </Form.Item>
                              </div>
                            </Form>
                          </div>
                        </Card>
                        <Modal
                          open={previewOpen}
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="example"
                            style={{
                              width: "100%",
                            }}
                            src={previewImage}
                          />
                        </Modal>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default EditProfileArtist;
