import React, {useState} from 'react';
import { Layout, Menu, Avatar } from 'antd';
import SavedArtist from './savedArtist';
// import PastArtist from './pastArtist';
import { AvatarImg } from "../../constant/images";
import {UserOutlined} from '@ant-design/icons';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const { Sider } = Layout;

const Index = () => {
    let navigate = useNavigate();
    const userDetails = useSelector((state) => state?.User?.userDetails);
    const [current, setCurrent] = useState('saved-artist');

    const onClick = (e) => {
        setCurrent(e.key);
        navigate("/" + e.key);
    };

    return (
        <div className='clientDashWrapper'>
            <div className='innerWrap'>
                <Layout style={{
                    padding: '2.5rem 1rem',
                    background: '#FFF',
                    minHeight:'70vh'
                }}>
                    <Sider
                        className='dashSidebar Layout-mobile'
                        breakpoint="lg"
                        collapsedWidth="0"
                        width={300}
                        style={{
                            background: '#d62b47',
                            borderRadius: '10px'
                        }}
                    >
                        <div className='custAvatar d-flex'>
                            <Avatar 
                                className='me-2'
                                size={'large'}
                                src={AvatarImg ? AvatarImg : ''}
                            />
                            <div className='userData'>
                                <h6>{userDetails?.name}</h6>
                                <p>User</p>
                            </div>
                        </div>
                        <Menu
                            selectedKeys={[current]}
                            onClick={onClick}
                            mode="inline"
                            items={[
                                {
                                  key: 'saved-artist',
                                  icon: <UserOutlined />,
                                  label: ('Saved Artist'),
                                },
                                {
                                  key: 'past-artist',
                                  icon: <UserOutlined />,
                                  label: ('Past Artist'),
                                },
                                {
                                  key: '3',
                                  icon: '',
                                  label: (''),
                                },
                            ]}
                        />
                        
                    </Sider>
                    <SavedArtist /> 
                </Layout>
            </div>
        </div>
    )
}

export default Index;