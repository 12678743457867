import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, notification, Steps, Progress } from "antd";
import Box from "@mui/material/Box";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import StepFour from "./stepFour";
import StepFive from "./stepFive";
import { useNavigate } from "react-router-dom";
import * as profileService from "../../../services/createProfile";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Country, State, City }  from 'country-state-city';

const GetStarted = () => {
    const [form] = Form.useForm();
    const [activeStep, setActiveStep] = useState(0);
    let navigate = useNavigate();
    var dispatch = useDispatch();
    const [tags, setTags] = useState([]);
    const [artistData, setArtistData] = useState();
    const userDetails = useSelector((state) => state?.User?.userDetails);
    const [albums, setAlbums] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [city, setCity] = useState([]);
    const [cities, setCities] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [stateId, setStateId] = useState('');
    const [currency, setCurrency] = useState('');

    useEffect(() => {
        const fetchCountries = () => {
            let countries = Country.getAllCountries();
            console.log({countries});
            setCountries(countries)
            setCurrency(currency);
        }
        fetchCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        const fetchState = () => {
            let states = State.getStatesOfCountry(countryId);
            console.log({states});
            setStates(states);
        }
        
        fetchState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryId]);
    
    useEffect(() => {
        const fetchCity = () => {
            let city = City.getCitiesOfState(countryId, stateId);
            console.log(city);
            setCity(city);
        }
        fetchCity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateId]);
    
    useEffect(() => {
        const fetchAllCities = () => {
            let cities = City.getAllCities();
            console.log(cities);
            setCities(cities);
        }
        fetchAllCities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryId]);

    const fetchTags = () => {
        dispatch(profileService.getTags())
            .then((res) => {
                console.info(res);
                setTags(res.data);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    };

    const fetchArtistData = (user_id) => {
        dispatch(profileService.getArtistData(user_id))
            .then((res) => {
                console.info(res);
                setArtistData({
                    ...artistData,
                    artist_name: res.data.user_profile.artist_name,
                    category_name: res.data.user_profile.category_name,
                    ph_ext: res.data.user_profile.ph_ext,
                    phone_number: res.data.user_profile.phone_number,
                    country: res.data.user_profile.country,
                    first_name: res.data.user_profile.first_name,
                    last_name: res.data.user_profile.last_name,
                    plan_id: res.data.user_profile.plan_id,
                });
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    };

    console.log({artistData})

    useEffect(() => {
        fetchTags();
        fetchArtistData(userDetails?.user_id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [profileInput, setProfileInput] = useState({
        state: "",
        city: "",
        zip_code: "",
        address_line1: "",
        address_line2: "",
        business_website_address: "",
        profile_cover: "",
        select_area_ids: "",
        tattoo_service_ids: "",
        from_price: "",
        to_price: "",
        additional_details: "",
        currency: "",
        artist_name: "",
        category_name: "",
        ph_ext: "",
        phone_number: "",
        country: "",
        first_name: "",
        last_name: "",
        albums: "",
        profile_photo_id: '',
    });

    const handleInput = (input, key) => (e) => {
        
        console.warn("na",e)
        if (
            input === "business_country"
        ) {
            console.log({input})
            setCountryId(e);
            let i = Country.getCountryByCode(e)
            setProfileInput({ ...profileInput, [input]: i.name });
        }else if(input === "state"){
            let i = State.getStateByCodeAndCountry(e, countryId)
            setStateId(e);
            setProfileInput({ ...profileInput, [input]: i.name });
        }else if(input === "city"){
            setProfileInput({ ...profileInput, [input]: e });
        }else if(input === "select_area_ids"){
            setProfileInput({ ...profileInput, [input]: e });
        }else if(input === "currency" || input === "tattoo_service_ids"){
            setProfileInput({ ...profileInput, [input]: e });
        } else {
            //console.warn({ profileInput });
            setProfileInput({ ...profileInput, [input]: e.target.value });
        }
        console.warn({ profileInput });
    };

    const handleRepeater = (e, key, type) => {
        console.log({ e });
        let inputValue = type === "name" ? e.target.value : e;
        let albumsData = [...albums];
        if (albumsData[key]) {
            albumsData[key][type] = inputValue;
        } else {
            albumsData[key] = {};
            albumsData[key][type] = inputValue;
        }
        setAlbums(albumsData);
    };

    const handleChangeImage = (key) => ({ fileList: newFileList }) => {
        console.log({newFileList})
        setFileList(newFileList);
        let uploadedAlbums = [...albums];
        uploadedAlbums[key] = uploadedAlbums[key] || {};
        uploadedAlbums[key] = { ...uploadedAlbums[key], images: newFileList };
        setAlbums(uploadedAlbums);
    };

   

    useEffect(() => {
        setProfileInput(profileInput);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const steps = [
        {
            index: 1,
            title: 'Business Details',
            content: <StepOne handleInput={handleInput} profileInput={profileInput} states={states} country={countries} city={city}/>,
            fields: ['address_line1', 'city', 'state', 'country', 'zip_code']
        },
        {
            index: 2,
            title: 'Services',
            content: <StepTwo
                        handleInput={handleInput}
                        profileInput={profileInput}
                        tags={tags}
                        states={states} 
                        country={countries}
                        cities={cities}
                    />,
            fields: ['select_area_ids', 'tattoo_service_ids']
        },
        {
            index: 3,
            title: 'Profile',
            content: <StepThree handleInput={handleInput} profileInput={profileInput} />,
            fields: ['select_area_ids', 'tattoo_service_ids']
        },
        {
            index: 4,
            title: 'Rates',
            content: <StepFour 
                handleInput={handleInput} 
                profileInput={profileInput}
                country={countries}
            />,
            fields: ['from_price', 'to_price']
        },
        {
            index: 5,
            title: 'Portfolio',
            content: <StepFive
                        handleInput={handleInput}
                        profileInput={profileInput}
                        handleRepeater={handleRepeater}
                        fileList={fileList}
                        handleChangeImage={handleChangeImage}
                        tags={tags}
                        albums={albums}
                        artistData={artistData}
                    />,
            fields: ['album_name', 'album_tag_ids', 'albumImages',]
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    
    const handleNext = async () => {
        window.scrollTo(0, 0);
        try {
            await form.validateFields(
                [
                    'address_line1', 
                    'city', 
                    'state', 
                    'country', 
                    'zip_code', 
                    'select_area_ids', 
                    'tattoo_service_ids',
                    'profile_photo',
                    'from_price',
                    'to_price',
                    'album_name',
                    'album_tag_ids',
                    'albumImages',
                ]
            );
            setActiveStep(activeStep + 1);
        } catch {
            console.log('Validation error')
        }
    };

    const skipStep = () => {
        setActiveStep((activeStep) => activeStep + 1);
    }

    const handleBack = () => {
        window.scrollTo(0, 0);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onFinish = async() => {
        console.log('Success:', albums, profileInput);
        await form.validateFields(['profileInput.albumImages','profileInput.album_tag_ids','profileInput.album_name'])
        if(albums.length > 0){
            albums.forEach((album) => {
                if(album.images.length > 0){
                    album.images.forEach((image) => {
                        if(album["image_ids"]){
                            album.image_ids.push(image.response)
                        }else{
                            album["image_ids"] = [image.response]
                        }
                       
                    })
                    delete album["images"]
                }
            })
        }
        const data = {
            // artist_name: artistData?.artist_name,
            // category_name: artistData?.category_name,
            // ph_ext: artistData?.ph_ext,
            // phone_number: artistData?.phone_number,
            // country: artistData?.country,
            // first_name: artistData?.first_name,
            // last_name: artistData?.last_name,
            business_country: profileInput?.business_country,
            plan_id: artistData?.plan_id,
            user_id: userDetails.user_id,
            address_line1: profileInput.address_line1,
            address_line2: profileInput.address_line2,
            city: profileInput.city,
            state: profileInput.state,
            zip_code: profileInput.zip_code,
            business_website_address: profileInput.business_website_address,
            profile_photo_id: profileInput.profile_photo_id,
            select_area_ids: profileInput.select_area_ids,
            tattoo_service_ids: profileInput.tattoo_service_ids,
            from_price: profileInput.from_price,
            to_price: profileInput.to_price,
            additional_details: profileInput.additional_details,
            currency: profileInput.currency,
            albums: albums,
        };
        

            
        dispatch(profileService.createArtistDetails(data))
            .then((res) => {
                notification.open({
                    message: "Profile successfully created!",
                    icon: (
                        <CheckCircleOutlined
                            style={{
                                color: "#d62b47",
                            }}
                        />
                    ),
                });
                //return;
                navigate('/user-profile/?user_id=' + userDetails.user_id)
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className="businessWrapper">
            <div className="businessWrapInner">
                <div className="container-fluid">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                        <Col xs={24} md={24} xl={24}>
                            <div className="titleRow">
                                <h3 className="pageTitle">
                                    Let’s get started by creating your profile
                                </h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                        justify="center"
                        className="my-4"
                    >
                        <Col xs={24} md={24} xl={16}>
                            <Box sx={{ my: 5 }}>
                                <Steps current={activeStep} items={items} className="custom-step" />
                                <Progress
                                    percent={((activeStep) / steps.length) * 100}
                                    size="small" 
                                />
                            </Box>
                        </Col>
                        <Col xs={24} md={24} xl={16}>
                            <Card bordered={false} className="getStartedCard">
                                <Form
                                    name="basic"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className="customForm"
                                    layout="vertical"
                                    form={form}
                                >
                                    <div className="step-content">
                                        {/* {getStepContent(activeStep)} */}
                                        {steps[activeStep].content}
                                        <div className="btnGroup">
                                            <div className="d-flex" >
                                                <Button
                                                    className="btnBack w-auto mobbtn"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                >
                                                    Back
                                                </Button>
                                                {activeStep === steps.length - 1 ? ("") : 
                                                (
                                                <Button
                                                    className="btnBack w-auto mobbtn"
                                                    onClick={skipStep}
                                                >
                                                    Skip
                                                </Button>
                                                )}
                                            </div>
                                            {activeStep === steps.length - 1 ? (
                                                <Button
                                                    className="submitBtn btnNext w-auto mobbtn"
                                                    onClick={() => onFinish()}
                                                    disabled={
                                                        !form.isFieldsTouched(true) ||
                                                        form.getFieldsError().filter(({ errors }) => errors.length)
                                                          .length > 0
                                                      }
                                                >
                                                    Finish
                                                </Button>
                                            ) : (
                                                <div className="d-flex">
                                                        <Button
                                                            className="btnNext w-auto mobbtn"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => onFinish()}
                                                        >
                                                            Skip All
                                                        </Button>
                                                    <Button
                                                        className="btnNext w-auto mobbtn"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleNext}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                                
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default GetStarted;
