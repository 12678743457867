import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Input, notification, Button } from 'antd';
// import { PageBanner } from '../../components/Banner/pageBanner';
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import * as ContactService from '../../services/contactService';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutlined } from "@ant-design/icons";
import * as getContactTemplate from "../../services/ContactUsService"

const ContactCard = (props) => {
    return (
        <Card bordered={false} className="contactCard">
            <div className='cardInner'>
                <div className='topSection'>
                    <div className='cardIcon'>
                        {props.icon}
                    </div>
                </div>
                <div className='bottomSection'>
                    <h4 className='cardTitle'>{props.title}</h4>
                    <p className='cardText'>{props.text}</p>
                    <p className='cardText'>{props.textTwo}</p>
                </div>
            </div>
        </Card>
    );
}

const ContactUs = () => {
    var dispatch = useDispatch();
    let navigate = useNavigate();
    const [ loadings, setLoadings ] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [ contactInput, setContactInput ] = useState({
        name: '',
        email: '',
        message: '',
    });

    const bannerStyle = {
        backgroundImage: `url(${contactData?.template_photo})`,
        backgroundSize: 'cover',
        height: '450px',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[index] = true;
          return newLoadings;
        });
        setTimeout(() => {
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
          });
        }, 5000);
    };
    const getContactUsServiceData = () => {
        dispatch(getContactTemplate.getContactUsService())
        .then((res) => {
            setLoadings(true);
            console.info(res);
            setContactData(res?.data);
            setLoadings(false);
        })
        .catch((err) => {
            console.info("Get Error Edit State...");
        });
    }
    const handleInput = (e) => {
        //console.warn('Handle Input', e.target.value);
        setContactInput({...contactInput, [e.target.name]: e.target.value});
    }

    const onFinish = () => {
        // enterLoading(0);
        const data = {
            name: contactInput.name,
            email: contactInput.email,
            message: contactInput.message
        }
        console.log('Success:', data);

        dispatch(ContactService.contactUs(data))
        .then((res) => {
            console.log({res});
            notification.open({
                message: 'Contact form submitted successfully!',
                icon: (
                    <CheckCircleOutlined
                        style={{
                            color: '#d62b47',
                        }}
                    />
                ),
            })
            navigate('/');
        })
        .catch((err) => {
            console.log({err});
        })
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        getContactUsServiceData();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    console.log({contactData});

    return (
        <div className='contactWrapper'>
            <div className='contactInnerWrap'>
                {/* <PageBanner
                    bannerText="Contact"
                    colorText="Us"
                    bannerSubText="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet."
                /> */}
                <div className='bannerWrapper'>
            <div className='innerWrap'>
                <div className='bannerSec' style={bannerStyle}>
                    <div className='bannerContent'>
                        <h3 className='secTitle'>{contactData?.template_name}
                        {/* <span className='colorText'>{colorText}</span> */}
                        </h3>
                        <p className='subText'>{contactData?.sub_heading}</p>
                    </div>
                </div>
            </div>
        </div>
                <div className='container'>
                    <div className='contentWrap'>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                            <Col xs={24} md={8} lg={8}>
                                <div className='leftWrapper'>
                                    <h3 className='leftSecTitle'>Let's make something great together.</h3>
                                    <div className='contactCardSec'>
                                        <ContactCard
                                            icon={<BsTelephone />}
                                            title="Phone"
                                            text="+ 000 000 0000"
                                            textTwo="+ 000 000 1234"
                                        />
                                        <ContactCard
                                            icon={<BsEnvelope />}
                                            title="Email"
                                            text="support@findingink.com"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} md={16} lg={16}>
                                <div className='rightWrapper'>
                                    <p className='rightSecDesc'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde ut optio laboriosam vitae
                                        ratione iure blanditiis ipsa harum delectus praesentium soluta autem, repellat voluptatem
                                        illum libero earum tempore eius nemo! Animi reiciendis amet magni, placeat vitae quisquam.
                                    </p>
                                    <div className='contactCardWrap'>
                                        <Card bordered={false} className="contactFormCard">
                                            <div className='cardInner'>
                                                <div className='formTitle'>
                                                    <h4>Get in Touch</h4>
                                                    <p>Fill the form below so we can get to know you and your needs better.</p>
                                                </div>
                                                <Form
                                                    name='contactForm'
                                                    onFinish={onFinish}
                                                    onFinishFailed={onFinishFailed}
                                                    autoComplete="off"
                                                >
                                                    <Form.Item
                                                        name="name"
                                                        style={{
                                                            display: 'inline-block',
                                                            width: 'calc(50% - 16px)',
                                                        }}
                                                        rules={[{
                                                            required: true,
                                                            message: 'Please input your Name!'
                                                        }]}
                                                    >
                                                        <Input 
                                                            name="name"
                                                            placeholder='Name' 
                                                            className="formControl"
                                                            onChange={handleInput} 
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="email"
                                                        style={{
                                                            display: 'inline-block',
                                                            width: 'calc(50% - 16px)',
                                                            margin: '0 16px'
                                                        }}
                                                        rules={[
                                                            {
                                                              type: 'email',
                                                              message: 'The input is not valid E-mail!',
                                                            },
                                                            {
                                                              required: true,
                                                              message: 'Please input your E-mail!',
                                                            },
                                                          ]}
                                                    >
                                                        
                                                        <Input 
                                                            placeholder='Email' 
                                                            className="formControl"
                                                            name="email"
                                                            onChange={handleInput}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="message"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Please input your Message!'
                                                        }]}
                                                    >
                                                        <Input.TextArea 
                                                            placeholder='Message' 
                                                            className="formControl" 
                                                            rows={10}
                                                            name="message"
                                                            onChange={handleInput}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="button" className='text-center'>
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            loading={loadings[0]}
                                                            className="submitBtn w-auto"
                                                        >Submit</Button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;