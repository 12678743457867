import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Alert, Divider, Form, Upload, Modal, Input, Spin, Space, Button, Select } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
    PrimaryButton, SecondaryButton,
} from '../../../components/Buttons/buttons';
import axios from "axios";
import { TATTOO_URL } from "../../../Helper";
import * as profileService from "../../../services/createProfile";
import { useDispatch, useSelector } from "react-redux";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
    
const Option = Select.Option;

const EditProfile = () => {
    let navigate = useNavigate();
    var dispatch = useDispatch();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState();
    const [fileList, setFileList] = useState([]);
    const [slider, setSlider] = useState([]);
    const userDetails = useSelector((state) => state?.User?.userDetails);
    const [artistData, setArtistData] = useState();
    const [tags, setTags] = useState([]);
    const [albums, setAlbums] = useState([
        { album_name: "", album_tag_ids: [], albumImages:[] },
    ]);
    const [fileListNew, setFileListNew] = useState([]);
    //const [albumList, setAlbumList] = useState([]);

    const fetchTags = () => {
        dispatch(profileService.getTags())
            .then((res) => {
                console.info(res);
                setTags(res.data);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    };

    useEffect(() => {
        fetchTags();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [profileInput, setProfileInput] = useState({
        album_name: "",
        album_tag_ids: ''
    });

    const handleRepeater = (e, key, type) => {
        let inputValue = type === "name" ? e.target.value : e;
        let albumsData = [...albums];
        if (albumsData[key]) {
            albumsData[key][type] = inputValue;
        } else {
            albumsData[key] = {};
            albumsData[key][type] = inputValue;
        }
        setAlbums(albumsData);

        
        // let bath_area_val = "";
        // if (input === "bath_area") {
        //   bath_area_val = e.target.value;
        // } else {
        //   bath_area_val = e;
        // }
        // const list = [...bathArea];
        // list[index][input] = bath_area_val;
        // setBathArea(list);
        // console.warn("bathArea", bathArea);
    };

    const handleImageChange = (key) => ({ fileListNew: newFileList }) => {
        setFileListNew(newFileList);
        let uploadedAlbums = [...albums];
        uploadedAlbums[key] = uploadedAlbums[key] || {};
        uploadedAlbums[key] = { ...uploadedAlbums[key], images: newFileList };
        setAlbums(uploadedAlbums);
    };

    useEffect(() => {
        setProfileInput(profileInput);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const fetchArtistData = (user_id) => {
        dispatch(profileService.getArtistData(user_id))
            .then((res) => {
                setLoading(true);
                console.info(res);
                setArtistData({
                    ...artistData,
                    artist_name: res.data.data.artist_name,
                    category_name: res.data.data.category_name,
                    ph_ext: res.data.data.ph_ext,
                    phone_number: res.data.data.phone_number,
                    country: res.data.data.country,
                    first_name: res.data.data.first_name,
                    last_name: res.data.data.last_name,
                    slider_media_id: res.data.data.slider_media_id,
                    album_image_ids: res.data.data.album_image_ids,
                });
                setLoading(false);
                if(artistData.slider_media_id) {
                    let slider_data_old = [...slider];
                    res.data.data.slider_media_id.forEach((slider) => {
                        slider_data_old.push(slider.id)
                        setSlider(slider_data_old);
                    })
                }
               
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    };

    useEffect(() => {
        fetchArtistData(userDetails?.user_id);
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadSlider = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const uploadImage = async (options) => {
        const { onSuccess, onError, file } = options;

        const fmData = new FormData();
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
        };
        fmData.append("file", file);
        try {
            const res = await axios.post(
                TATTOO_URL + "api/media_uploads",
                fmData,
                config
            );
            onSuccess(res.data.data.id);
            setImageUrl(res);
            console.log("server res: ", res, imageUrl, fileListNew);
        } catch (err) {
            console.log("Error: ", err);
            const error = new Error("Some error");
            onError({ err });
            console.warn(error);
        }
    };

    const handleChangeImage = (info) => {
        setFileList(info.fileList);
        if (info.file.status === 'done') {
            setSlider([...slider, info.file.response]);
        }

    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const onFinish = () => {

        const data = {
            artist_name: artistData?.artist_name,
            category_name: artistData?.category_name,
            ph_ext: artistData?.ph_ext,
            phone_number: artistData?.phone_number,
            country: artistData?.country,
            first_name: artistData?.first_name,
            last_name: artistData?.last_name,
            user_id: userDetails.user_id,
            slider_media_id: slider,
        };
        console.log('Success:', data);
        dispatch(profileService.updateProfile(data))
            .then((res) => {
                //return;
                navigate('/user-profile/?user_id=' + userDetails.user_id)
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    console.log({artistData})

    return (
        <div className='editProfileWrapper'>
            {loading ? (
                <div className='spinDiv'>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <div className='innerWrap'>
                        <div className='container-fluid'>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify='center'>
                                <Col xs={24} md={18}>
                                    <Card
                                        bordered={false}
                                        className="profileCard"
                                    >
                                        <div className='cardBody'>
                                            <Form
                                                name='editProfileForm'
                                                onFinish={onFinish}
                                                onFinishFailed={onFinishFailed}
                                                autoComplete="off"
                                                layout='vertical'
                                            >
                                                <Divider orientation="left" className='dividerTitle'>Profile Slideshow</Divider>
                                                <div className='px-3'>
                                                    <Alert
                                                        message="Upgrade your plan to add up to 6 photos."
                                                        type="info"
                                                        showIcon
                                                        action={
                                                            <Link className="planLink" to={'/pricing'}>
                                                                Upgrade Plan
                                                            </Link>
                                                        }
                                                    />
                                                    <Form.Item
                                                        className='flex-wrap pt-3'
                                                        name="slider_media_id"
                                                    >
                                                        <Upload
                                                            listType="picture-card"
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeImage}
                                                            maxCount={10}
                                                            multiple
                                                            customRequest={uploadImage}
                                                            defaultFileList={artistData?.slider_media_id}
                                                        >
                                                            {fileList.length >= 10 ? null : uploadSlider}
                                                        </Upload>
                                                    </Form.Item>
                                                    <Divider orientation="left" className='dividerTitle'>Album</Divider>
                                                    <Alert
                                                        message="Showcase your portfolio with highlight photos."
                                                        type="info"
                                                        showIcon
                                                        closable
                                                        className='mb-5'
                                                    />
                                                    <Form.List name="albums" className="flex-wrap pt-3">
                                                    
                                                        {(fields, { add, remove }) => (
                                                            <>
                                                                {/* {artistData && artistData.album_image_ids.map((albm, name, index) => {
                                                                    return(
                                                                        <Space key={index} className="d-flex w-100 repeater" size={30}>
                                                                            <div className="w-100">
                                                                                <Form.Item
                                                                                    name={[name, "album_name"]}
                                                                                    label="Album Name"
                                                                                    className="w-100"
                                                                                >
                                                                                    <Input
                                                                                        className="formControl"
                                                                                        placeholder="Enter album name here"
                                                                                        onChange={(e) => handleRepeater(e, index, "name")}
                                                                                        defaultValue={albm.album_name}
                                                                                    />
                                                                                </Form.Item>
                                                                                <Form.Item
                                                                                    name={[name, "album_tag_ids"]}
                                                                                    label="Album Tags"
                                                                                    className="w-100"
                                                                                >
                                                                                    <Select
                                                                                        placeholder="What type of tattoos do you provide?"
                                                                                        className="formControl"
                                                                                        mode="multiple"
                                                                                        allowClear
                                                                                        onChange={(e) => handleRepeater(e, index, "tags")}
                                                                                        defaultValue={albm.album_tag}
                                                                                    >
                                                                                        {tags.map((tag) => {
                                                                                            return (
                                                                                                <Option value={tag} id={tag}>
                                                                                                    {tag}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select>
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div className="w-100 d-flex align-items-center">
                                                                                <Form.Item
                                                                                    name={[name, "albumImages"]}
                                                                                    label="Album Images"
                                                                                    className="flex-wrap w-100"
                                                                                >
                                                                                    <Upload
                                                                                        listType="picture-card"
                                                                                        onPreview={handlePreview}
                                                                                        onChange={handleImageChange(index)}
                                                                                        maxCount={10}
                                                                                        multiple
                                                                                        customRequest={uploadImage}
                                                                                        defaultFileList={artistData?.album_image_ids[name].image}
                                                                                    >
                                                                                        {fileList.length >= 10 ? null : uploadButton}
                                                                                    </Upload>
                                                                                    <Modal
                                                                                        open={previewOpen}
                                                                                        title={previewTitle}
                                                                                        footer={null}
                                                                                        onCancel={handleCancel}
                                                                                    >
                                                                                        <img
                                                                                            alt="example"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                            }}
                                                                                            src={previewImage}
                                                                                        />
                                                                                    </Modal>
                                                                                </Form.Item>
                                                                            </div>
                                                                        </Space>
                                                                    )
                                                                } )} */}
                                                                {fields.map(({ key, name, ...restField }) => (
                                                                    <Space key={key} className="d-flex w-100 repeater" size={30}>
                                                                        <div className="w-100">
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, "album_name"]}
                                                                                label="Album Name"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "Please enter album name here!",
                                                                                    },
                                                                                ]}
                                                                                className="w-100"
                                                                                id={key}
                                                                            >
                                                                                <Input
                                                                                    className="formControl"
                                                                                    placeholder="Enter album name here"
                                                                                    onChange={(e) => handleRepeater(e, key, "name")}
                                                                                    defaultValue={profileInput.album_name}
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, "album_tag_ids"]}
                                                                                label="Album Tags"
                                                                                className="w-100"
                                                                                id={key}
                                                                                rules={[
                                                                                    {
                                                                                      required: true,
                                                                                      message: "Please add album tag",
                                                                                    },
                                                                                  ]}
                                                                            >
                                                                                <Select
                                                                                    placeholder="What type of tattoos do you provide?"
                                                                                    className="formControl"
                                                                                    mode="multiple"
                                                                                    allowClear
                                                                                    onChange={(e) => handleRepeater(e, key, "tags")}
                                                                                //  defaultValue={albums[key].tags}
                                                                                >
                                                                                    {tags.map((tag) => {
                                                                                        return (
                                                                                            <Option value={tag} id={tag}>
                                                                                                {tag}
                                                                                            </Option>
                                                                                        );
                                                                                    })}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                        <div className="w-100 d-flex align-items-center">
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, "albumImages"]}
                                                                                label="Album Images"
                                                                                className="flex-wrap w-100"
                                                                            >
                                                                                <Upload
                                                                                    listType="picture-card"
                                                                                    onPreview={handlePreview}
                                                                                    onChange={handleImageChange(key)}
                                                                                    maxCount={10}
                                                                                    multiple
                                                                                    customRequest={uploadImage}
                                                                                >
                                                                                    {fileList.length >= 10 ? null : uploadButton}
                                                                                </Upload>
                                                                                <Modal
                                                                                    open={previewOpen}
                                                                                    title={previewTitle}
                                                                                    footer={null}
                                                                                    onCancel={handleCancel}
                                                                                >
                                                                                    <img
                                                                                        alt="example"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                        src={previewImage}
                                                                                    />
                                                                                </Modal>
                                                                            </Form.Item>
                                                                            <Button
                                                                                className="submitBtn w-auto h-auto"
                                                                                onClick={() => remove(name)}
                                                                            >
                                                                                <MinusCircleOutlined />
                                                                            </Button>
                                                                        </div>
                                                                    </Space>
                                                                ))}
                                                                {/* <Form.Item>
                                                                    <Button className="submitBtn w-auto" onClick={() => add()}>
                                                                        Add Album
                                                                    </Button>
                                                                </Form.Item> */}
                                                            </>
                                                        )}
                                                    </Form.List>
                                                    <Form.Item className='text-end'>
                                                        <SecondaryButton
                                                            type="primary"
                                                            handleClick={console.log()}
                                                            text="Cancel"
                                                            className="w-auto"
                                                        />
                                                        <PrimaryButton
                                                            type="primary"
                                                            handleClick={console.log()}
                                                            text="Update"
                                                            className="w-auto"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img
                            alt="example"
                            style={{
                                width: '100%',
                            }}
                            src={previewImage}
                        />
                    </Modal>
                </>
            )}
        </div>
    );
};

export default EditProfile;