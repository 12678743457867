import Http from "../Http";


export function editwriteReview(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
                Http.post('api/editReview', values)
                    .then((res) => {
                        return resolve(res);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}

export function deleteReviews(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
                Http.post('api/deleteReview', values)
                    .then((res) => {
                        return resolve(res);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}

export function editReviews(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
                Http.post('api/editArtistPersonalReview', values)
                    .then((res) => {
                        return resolve(res);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}

export function editgetReviews(value) {
    console.info("VAL",value)
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
              //  Http.get(`api/getArtistReviews?user_id=${value?.user_id}&user_profile_id=${value?.user_profile_id}`)
                Http.get(`api/getArtistReviews`, { params: value })
                    .then((res) => {
                        return resolve(res);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}
