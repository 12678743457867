import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spin, Select, Button, Form, notification } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as planService from "../../services/getPlans";
import Backdrop from "@mui/material/Backdrop";
import { AxiosError } from "axios";

const Index = () => {
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  // const user_id = queryParams.get("user_id");
  const upgradePlan = queryParams.get("upgrade_plan");
  const userDetails = useSelector((state) => state?.User?.userDetails);
  // const [month, setMonth] = useState();
  const [getPlan, setGetPlan] = useState();
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [freePackID, setFreePackID] = useState("5");
  const [proPackID, setProPackID] = useState("");
  const [PremiumPackID, setPremiumPackID] = useState("");
  const [packInterval, setPackInterval] = useState("Monthly");

  const getUserPlan = (user_id) => {
    setLoader(true);
    setOpen(true);
    dispatch(planService.getArtistPlan(user_id))
      .then((res) => {
        setGetPlan(res.data);
        setLoader(false);
        setOpen(false);
        checkPlan(res?.data);
      })
      .catch((err) => {
        console.log(err);
        checkPlan(err);
      });
  };

  useEffect(() => {
    let user_id = {
      user_id: userDetails?.user_id,
    };
    getUserPlan(user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numPlan = upgradePlan;
  const planNum = parseInt(numPlan);

  const fetchPlans = () => {
    setLoader(true);
    setOpen(true);
    dispatch(planService.getPlans())
      .then((res) => {
        setPlans(res.data);
        setLoader(false);
        setOpen(false);
        setPlansList(res?.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  useEffect(() => {
    fetchPlans();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPlan = (value) => {
    if (value?.name == "AxiosError") {
      localStorage.setItem("firstTime", true);
    } else {
      localStorage.setItem("firstTime", false);
    }
  };

  // check Existing Pack Id's
  const setPlansList = () => {
    if (plans) {
      if (packInterval == "Monthly") {
        setProPackID("3");
        setPremiumPackID("1");
      } else {
        setProPackID("4");
        setPremiumPackID("2");
      }
    }
  };
  function getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
  }

  const savePlan = (e) => {
    console.log(e,"rrrrrrrrrrrrrr")
    setLoader(true);
    setOpen(true);
    let plan_id = e;
    const data = {
      user_id: userDetails?.user_id,
      // plan_id: e === "Free" ? "5" : plan_id,
      plan_id: plan_id,
    };
    if (e === "5") {
      let checkFirstTime = localStorage.getItem("firstTime");
      console.log(checkFirstTime,"checkFirstTime")
      document.cookie = "selected_plan=" + e;
      dispatch(planService.saveArtistPlan(data))
        .then((res) => {
          // notification.open({
          //   message: "Plan selected successfully!",
          //   icon: (
          //     <CheckCircleOutlined
          //       style={{
          //         color: "#d62b47",
          //       }}
          //     />
          //   ),
          // });
          if (checkFirstTime == 'true') {
            console.log("yha aayaaaaa")
              navigate(
                "/create-profile-start/?user_id=" + userDetails?.user_id
              );
              notification.open({
                message: "Plan selected successfully!",
                icon: (
                  <CheckCircleOutlined
                    style={{
                      color: "#d62b47",
                    }}
                  />
                ),
              });
            } else {
              console.log("nahi aayaaa")
              navigate("/");
              notification.open({
                message: "Plan changed successfully!",
                icon: (
                  <CheckCircleOutlined
                    style={{
                      color: "#d62b47",
                    }}
                  />
                ),
              });
            }
        })
        .catch((err) => {
          console.log({ err });
          setLoader(false);
          setOpen(false);
        });
    } else {
      navigate(
            "/checkout/?user_id=" +
              userDetails?.user_id +
              "&plan_id=" +
              plan_id,
            { state: { plan_data: data } }
          );
      // const selectedPlan = getCookie("selected_plan");

      // console.log("yeh chala ya nahi")
      // if (checkFirstTime === false) {
      //   dispatch(planService.saveArtistPlan(data))
      //     .then((res) => {
      //       notification.open({
      //         message: "Plan changed successfully!",
      //         icon: (
      //           <CheckCircleOutlined
      //             style={{
      //               color: "#d62b47",
      //             }}
      //           />
      //         ),
      //       });
      //       navigate("/");
      //       console.log("ye kab aayega");
      //       if (checkFirstTime === true) {
      //         navigate(
      //           "/create-profile-start/?user_id=" + userDetails?.user_id
      //         );
      //       } else {
      //         navigate("/");
      //       }
      //     })
      //     .catch((err) => {
      //       console.log({ err });
      //       setLoader(false);
      //       setOpen(false);
      //     });
      // } else {
      //   navigate(
      //     "/checkout/?user_id=" +
      //       userDetails?.user_id +
      //       "&plan_id=" +
      //       plan_id,
      //     { state: { plan_data: data } }
      //   );
      // }
    }
    setLoader(false);
    setOpen(false);
  };

  const handleChange = (value) => {
    setLoader(true);
    setOpen(true);
    if (value == "Monthly") {
      setProPackID("3");
      setPremiumPackID("1");
      setPackInterval(value);
      setLoader(false);
      setOpen(false);
    } else {
      setProPackID("4");
      setPremiumPackID("2");
      setPackInterval(value);
      setLoader(false);
      setOpen(false);
    }
  };

  return (
    <>
      {loader ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <Spin size="large" />
        </Backdrop>
      ) : (
        " "
      )}
      <div className="pricingWrapper">
        <div className="innerWrap">
          <div className="container-fluid">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col xs={24} md={24} xl={24}>
                <div className="titleRow">
                  <h3 className="pageTitle">Plans & Pricing</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="filerSection">
            <Col xs={24} md={24}>
              <Select
                defaultValue={packInterval}
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: "Monthly", label: "Monthly" },
                  { value: "Yearly", label: "Yearly" },
                ]}
              />
            </Col>
          </Row>
          <div className="container-xxl my-5">
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
              justify="center"
              align="middle"
            >
              {Object.keys(plans).map((planCategory, index) => {
                return (
                  <Col xs={24} md={8} lg={6} key={index} style={{margin:'1rem auto'}}>
                    {" "}
                    <Card
                      key={index}
                      className={
                        getPlan?.name === plans[planCategory][0].name
                          ? " premiumBG pricingCard"
                          : "pricingCard"
                      }
                      bordered={false}
                      hoverable
                    >
                      <div className="cardBody">
                        <div className="cardHeader">
                          <div className="priceSection">
                            <div className="priceCrcl">
                              <span className="price">
                                {packInterval === "Monthly"
                                  ? plans[planCategory][0].name === "Free"
                                    ? "$0"
                                    : plans[planCategory][0].name === "Pro"
                                    ? "$15"
                                    : "$45"
                                  : plans[planCategory][0].name === "Free"
                                  ? "$0"
                                  : plans[planCategory][0].name === "Pro"
                                  ? "$150"
                                  : "$450"}
                              </span>
                            </div>
                            <div className="pricingPlan">
                              <p>{planCategory}</p>
                              {plans[planCategory][0].name === "Free" ? (
                                ""
                              ) : (
                                <span className="small">/per month</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="cardInner">
                          <div className="innerCardwrap">
                            {/* <p className="description">
                                {plans[planCategory][0].slug}
                              </p> */}
                            {plans[planCategory][0].name === "Free" ? (
                              <>
                                <div className="planDetails">
                                  <p className="dtList">5 Albums</p>
                                  <p className="dtList">10 Images per album</p>
                                  <p className="dtList">No Videos</p>
                                </div>
                                <div className="planBtn">
                                  <Button
                                    type="primary"
                                    onClick={() => savePlan(freePackID)}
                                    className={
                                      getPlan?.name ===
                                      plans[planCategory][0].name
                                        ? "w-auto bgGreen"
                                        : "submitBtn w-auto"
                                    }
                                  >
                                    {getPlan?.name ===
                                    plans[planCategory][0].name
                                      ? "Selected"
                                      : getPlan?.name === "Free"
                                      ? "Upgrade"
                                      : getPlan?.name === "Premium"
                                      ? "Downgrade"
                                      : "Get Started"}
                                  </Button>
                                </div>
                              </>
                            ) : plans[planCategory][0].name === "Premium" ? (
                              <>
                                <Form>
                                  <div className="planDetails">
                                    <p className="dtList">Unlimited Albums</p>
                                    <p className="dtList">
                                      Unlimited images per album
                                    </p>
                                    <p className="dtList">
                                      Unlimited videos per album
                                    </p>
                                    <p className="dtList">
                                      1 x 48hrs "profile boost"
                                    </p>
                                    <p className="dtList">
                                      Option (appear first in search results)
                                      per month
                                    </p>
                                  </div>
                                  <div className="planBtn">
                                    <Button
                                      htmlType="submit"
                                      type="primary"
                                      onClick={() => savePlan(PremiumPackID)}
                                      className={
                                        getPlan?.name ===
                                        plans[planCategory][0].name
                                          ? "w-auto bgGreen"
                                          : "submitBtn w-auto"
                                      }
                                    >
                                      {getPlan?.name ===
                                      plans[planCategory][0].name
                                        ? "Selected"
                                        : userDetails.user_id === planNum
                                        ? "Upgrade"
                                        : "Get Started"}
                                    </Button>
                                  </div>
                                </Form>
                              </>
                            ) : (
                              <>
                                <Form>
                                  <div className="planDetails">
                                    <p className="dtList">50 albums</p>
                                    <p className="dtList">
                                      10 Images per album
                                    </p>
                                    <p className="dtList">1 Video per album</p>
                                  </div>
                                  <div className="planBtn btnCard">
                                    <Button
                                      htmlType="submit"
                                      type="primary"
                                      onClick={() => savePlan(proPackID)}
                                      className={
                                        getPlan?.name === "Pro"
                                          ? "w-auto bgGreen"
                                          : "submitBtn w-auto"
                                      }
                                    >
                                      {getPlan?.name === "Pro"
                                        ? "Selected"
                                        : getPlan?.name === "Free"
                                        ? "Upgrade"
                                        : getPlan?.name === "Premium"
                                        ? "Downgrade"
                                        : "Get Started"}{" "}
                                    </Button>
                                  </div>
                                </Form>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
